import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

class ColorLegend extends Component {
    render() {
        const items = this.props.items;
        var list = items.map((item, index) => {
            const block = (
                <div
                    style={{
                        background: item.color,
                        width: "40px",
                        height: "20px",
                        margin: "0px 20px",
                    }}
                ></div>
            );
            const name = <span style={{ padding: 0 }}>{item.title}</span>;
            return (
                <Row
                    key={index}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "5px",
                    }}
                >
                    {block}
                    {name}
                </Row>
            );
        });
        return (
            <Row
                style={{
                    width: "auto",
                    background: "white",
                    padding: "5px 10px",
                }}
            >
                <Col>{list}</Col>
            </Row>
        );
    }
}

export default ColorLegend;
