import React, { Component } from "react";
import {
    LineSeries,
    XYPlot,
    VerticalGridLines,
    HorizontalGridLines,
    XAxis,
    YAxis,
} from "react-vis";
import { ENDPOINT } from "../../secrets";
import axios from "axios";
import { Container, Row } from "react-bootstrap";
import moment from "moment";

import ColorLegend from "./ColorLegend";

class CanvasGrowthGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canvases: [],
            userType: this.props.userType,
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        const url = `${ENDPOINT}api/statistics/canvas`;
        const query = {};
        axios
            .post(url, query)
            .then((response) => {
                const canvases = response.data.canvases;
                this.setState({
                    canvases: canvases,
                    loading: false,
                });
                console.log(canvases.length);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    tickFormatter = (value, index, scale, tickTotal) => {
        const time = moment(value);
        return time.format("DD MMM YYYY");
    };

    getGraphData = (condition, graphName) => {
        var count = 0;
        var data = [];
        var prev = 0;
        this.state.canvases.forEach((canvas) => {
            if (canvas.createdAt && condition(canvas)) {
                count++;
                const time = new Date(canvas.createdAt).getTime();
                console.log(time);
                if (time > prev) {
                    prev = time;
                } else {
                    console.log("Decrease");
                }
                data.push({ x: time, y: count });
            }
        });
        var data_rev = data.slice();
        data_rev = data_rev.reverse();
        data = [...data, ...data_rev];
        return data;
    };

    renderGraph = () => {
        var active_data = this.getGraphData((c) => {
            return c.client && c.stage !== "ON_HOLD" && c.stage !== "COMPLETED";
        });
        var stagnant_data = this.getGraphData((c) => {
            return !c.client;
        });
        var on_hold_data = this.getGraphData((c) => {
            return c.client && c.stage === "ON_HOLD";
        });
        var completed_data = this.getGraphData((c) => {
            return c.client && c.stage === "COMPLETED";
        });

        var total_data = this.getGraphData((c) => {
            return c.client;
        });

        const legend = [
            {
                color: "#000000",
                title: "Total",
            },
            {
                color: "#00d13c",
                title: "Active",
            },
            {
                color: "#535b3e",
                title: "Stagnant",
            },
            {
                color: "#cc3333",
                title: "On Hold",
            },
            {
                color: "#009999",
                title: "Complete",
            },
        ];

        const lineStyle = {
            stroke: "#9af",
            line: {
                strokeWidth: "2px",
            },
        };
        const gridStyle = {
            stroke: "#cdf",
            strokeDasharray: "5,5",
        };
        return (
            <XYPlot height={300} width={800}>
                <VerticalGridLines style={gridStyle} />
                <HorizontalGridLines style={gridStyle} />
                <YAxis style={lineStyle} title={"Number"} />
                <XAxis
                    tickTotal={10}
                    style={lineStyle}
                    tickFormat={this.tickFormatter}
                    title={"Time"}
                />
                <LineSeries
                    data={active_data}
                    color='#00d13c'
                    strokeWidth={5}
                />
                <LineSeries
                    data={on_hold_data}
                    color='#cc3333'
                    strokeWidth={5}
                />
                <LineSeries
                    data={completed_data}
                    color='#009999'
                    strokeWidth={5}
                />
                <LineSeries data={total_data} color='#000000' strokeWidth={5} />
            </XYPlot>
        );
    };

    render() {
        return (
            <Container
                style={{
                    borderBottom: "1px solid #999",
                    margin: "20px 0",
                    padding: "20px 20px",
                }}
            >
                <Row>
                    <h3>{this.props.title}</h3>
                </Row>
                <Row>{this.renderGraph()}</Row>
            </Container>
        );
    }
}

export default CanvasGrowthGraph;
