import React, { Component } from "react";
import axios from "axios";
import { Container, Col, Row } from "react-bootstrap";
import { ENDPOINT } from "../../secrets";

import ColorLegend from "./ColorLegend";

import {
    XYPlot,
    LineSeries,
    VerticalBarSeries,
    ArcSeries,
    DiscreteColorLegend,
    RadialChart,
} from "react-vis";

class UserStats extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            loading: true,
            userType: this.props.userType,
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        const url = `${ENDPOINT}api/statistics/users`;
        const query = {
            user_type: { $in: this.state.userType },
        };
        axios
            .post(url, query)
            .then((response) => {
                const users = response.data.users;
                this.setState({
                    users: users,
                    loading: false,
                });
                console.log(users.length);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    renderUserChart = (userType) => {
        if (this.state.loading) {
            return <p>Loading...</p>;
        }
        var count = 0;
        var anti_count = 0;
        this.state.users.forEach((user) => {
            switch (user.user_type) {
                case "MAKER":
                    if (user.fabrication_canvas.length > 0) count++;
                    else anti_count++;
                    break;
                case "DESIGNER":
                    if (user.design_canvas.length > 0) count++;
                    else anti_count++;
                    break;
                case "DESIGNER_MAKER":
                    if (user.design_canvas.length > 0) count++;
                    else if (user.fabrication_canvas > 0) count++;
                    else anti_count++;
                    break;
                case "USER":
                    if (user.user_canvas.length > 0) count++;
                    else anti_count++;
                    break;
                default:
                    break;
            }
        });
        return this.renderChart(count, anti_count);
    };

    renderChart = (count, anti_count) => {
        const angle = 2 * Math.PI * (count / (anti_count + count));
        var data = [
            {
                angle0: 0,
                angle: angle,
                radius: 60,
                radius0: 30,
                color: "#00d13c",
                label: "Assigned",
            },
            {
                angle0: angle,
                angle: Math.PI * 2,
                radius: 60,
                radius0: 30,
                color: "#535b3e",
                label: "Not Assigned",
            },
        ];

        const legend = data.map((item) => {
            return {
                title: item.label,
                color: item.color,
                strokeStyle: "wide",
            };
        });
        return (
            <Col>
                <Row className='justify-content-md-center'>
                    <h3>{this.props.title}</h3>
                </Row>
                <Row className='justify-content-md-center'>
                    <Col>
                        <p style={{ margin: "2px" }}>
                            Total : {count + anti_count}
                        </p>
                        <p style={{ margin: "2px" }}>
                            Users Assigned : {count}
                        </p>
                        <p style={{ margin: "2px" }}>
                            Users Not Assigned : {anti_count}
                        </p>
                    </Col>
                </Row>
                <Row className='justify-content-md-center'>
                    <XYPlot
                        xDomain={[-5, 5]}
                        yDomain={[-5, 5]}
                        width={200}
                        height={200}
                    >
                        <ArcSeries
                            animation
                            radiusType={"literal"}
                            center={{ x: 0, y: 0 }}
                            data={data}
                            colorType={"literal"}
                        />
                    </XYPlot>
                </Row>
                <Row className='justify-content-md-center'>
                    <ColorLegend items={legend}></ColorLegend>
                </Row>
                <hr />
            </Col>
        );
    };

    render() {
        return (
            <Row
                style={{
                    margin: "10px 20px",
                    padding: "20px 70px",
                    background: "#fff",
                    border: "1px solid #eee",
                }}
                className='justify-content-md-center'
            >
                {this.renderUserChart(this.state.userType)}
            </Row>
        );
    }
}

export default UserStats;
