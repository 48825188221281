import React, { Component } from 'react'
import {Row, Col, Button, Form} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import {ENDPOINT} from '../../secrets'
import axios from 'axios'
import Option from './Option'
import '../../stylesheets/question.css'

class Question extends Component{
    constructor(props)
    {
        super(props)
        let id = undefined
        if(props.data._id && props.mode !== "DUPLICATE")
        {
            id = props.data._id
        }
        else
        {
            id=undefined
        }

        this.state = {
            data: props.data,
            id: props.mode === 'CREATE'? undefined: id,
            main: props.mode === 'CREATE'? "":props.data.main,
            help_text: props.mode === 'CREATE'? "":props.data.help_text,
            tag: props.mode === 'CREATE'? "":props.data.tag,
            options: props.mode === 'CREATE'? []:props.data.options,
            mode : props.mode === 'undefined'? "READ": props.mode,
            option_ids: props.mode === 'CREATE'? []:props.data.options.map(x => x._id),
            nCreateOptions: 0,
            limit: props.mode === 'CREATE'? undefined:props.data.limit,
        }

        this.prevdata = props.data

        this.newOptions = []
    }

    componentDidMount()
    {

    }

    addOption = (new_id) =>
    {
        this.setState({option_ids: [...this.state.option_ids, new_id]})
    }

    deleteOption = (id) =>
    {
        let newOptionIds = this.state.option_ids.filter(x => x!== id)
        let newOptions = this.state.options.filter(x => x._id !== id)
        this.setState({option_ids: newOptionIds, options: newOptions})
    }

    saveQuestion()
    {
        this.newOptions = []
        let data = {
            main: this.state.main,
            help_text: this.state.help_text,
            tag: this.state.tag,
            options: this.state.option_ids
        }

        if(this.state.limit)
        {
            data.limit = parseInt(this.state.limit)
        }

        if(typeof(this.state.id) !== 'undefined')
        {
            data.id = this.state.id
        
            axios.put(ENDPOINT + "api/question", data).then(
                response => response.data
            )
            .then(
                response => 
                {
                    // console.log(response)
                }
            )
        }
        else{
            
                axios.post(ENDPOINT + "api/question", data).then(
                    response => response.data
                )
                .then(
                    response => 
                    {
                        this.setState({id: response.id})
                        // console.log(response)
                    }
                ) 
                
            }
    }

    deleteQuestion = () =>
    {
        this.props.deleteFunction(this.state.id)
    }

    duplicateQuestion = () =>
    {
        this.props.duplicateFunction(this.props.data)
    }

    addNewOptionForm = () =>
    {
        let nCreateOptions = this.state.nCreateOptions+1
        this.setState({nCreateOptions: nCreateOptions})
    }

    handleChange = (e) =>
    {
        e.preventDefault()
        this.setState({[e.target.name] : e.target.value})
    }

    setEditMode = (e) => 
    {
        this.setState({mode: "EDIT"})
    }

    updateQuestion = (e) => 
    {
        e.preventDefault()
        console.log(this.state)

        this.setState({mode: "READ", options: [...this.state.options, ...this.newOptions], nCreateOptions: 0})
    }

    componentDidUpdate()
    {
        this.saveQuestion()
    }

    renderEditMode = () =>
    {
        let OptionsRender = []
        this.state.options.forEach(option_data => 
            {
                OptionsRender.push(<Option key={option_data._id} data={option_data} mode={"EDIT"} addOption={this.addOption} deleteOption={this.deleteOption}></Option>)
            })
        
        for(let i=0; i<this.state.nCreateOptions; i++)
        {
            OptionsRender.push(<Option key={i} data={{text:"", link:""}} mode={"CREATE"} addOption={this.addOption} deleteOption={this.deleteOption}></Option>)
        }

        return (

            
            <Col xs={12}  className="question">
                    <Row style={{"position": "relative"}}>
                        <Col xs={12}>
                            <Row>
                                <Col xs={8}>
                                    <Form.Control type="text" className="main col-12" name="main" value={this.state.main} onChange={this.handleChange}></Form.Control>
                                </Col>
                                <Col xs={4}>
                                    <Form.Control type="text" className="tag col-12" name="tag" value={this.state.tag} onChange={this.handleChange}></Form.Control>
                                </Col>

                            </Row>
                            <Row>
                                <Col xs={8}>
                                    <Form.Control type="text" className="help_text col-12" name="help_text" value={this.state.help_text} onChange={this.handleChange}></Form.Control>
                                </Col>
                                <Col xs={4}>
                                    <Form.Control type="text" className="help_text col-12" name="limit" value={this.state.limit} onChange={this.handleChange}></Form.Control>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12">
                            <Row>
                                {OptionsRender}
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Button variant="primary" onClick={this.addNewOptionForm}>Add Option</Button>
                            <Button variant="success" onClick={this.updateQuestion}>Save</Button>
                            <Button variant="danger" onClick={this.deleteQuestion}>Delete</Button>
                        </Col>
                </Row>
            </Col>
        )
    }

    renderDuplicateMode = () =>
    {
        let OptionsRender = []
        this.state.options.forEach(option_data => 
            {
                OptionsRender.push(<Option key={option_data._id} data={option_data} mode={"EDIT"} addOption={this.addOption} deleteOption={this.deleteOption}></Option>)
            })
        
        for(let i=0; i<this.state.nCreateOptions; i++)
        {
            OptionsRender.push(<Option key={i} data={{text:"", link:""}} mode={"CREATE"} addOption={this.addOption} deleteOption={this.deleteOption}></Option>)
        }

        return (

            
            <Col xs={12}  className="question">
                    <Row style={{"position": "relative"}}>
                        <Col xs={12}>
                            <Row>
                                <Col xs={8}>
                                    <Form.Control type="text" className="main col-12" name="main" value={this.state.main} onChange={this.handleChange}></Form.Control>
                                </Col>
                                <Col xs={4}>
                                    <Form.Control type="text" className="tag col-12" name="tag" value={this.state.tag} onChange={this.handleChange}></Form.Control>
                                </Col>

                            </Row>
                            <Row>
                                <Col xs={8}>
                                    <Form.Control type="text" className="help_text col-12" name="help_text" value={this.state.help_text} onChange={this.handleChange}></Form.Control>
                                </Col>
                                <Col xs={4}>
                                    <Form.Control type="text" className="help_text col-12" name="limit" value={this.state.limit} onChange={this.handleChange}></Form.Control>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12">
                            <Row>
                                {OptionsRender}
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Button variant="primary" onClick={this.addNewOptionForm}>Add Option</Button>
                            <Button variant="success" onClick={this.updateQuestion}>Save</Button>
                            {/* <Button variant="danger" onClick={this.deleteQuestion}>Delete</Button> */}
                        </Col>
                </Row>
            </Col>
        )
    }

    renderCreateMode = () =>
    {
        let OptionsRender = []
        this.state.options.forEach(option_data => 
            {
                OptionsRender.push(<Option key={option_data._id} data={option_data} mode={"EDIT"} addOption={this.addOption} deleteOption={this.deleteOption}></Option>)
            })

        for(let i=0; i<this.state.nCreateOptions; i++)
        {
            OptionsRender.push(<Option key={i} data={{text:"", link:""}} mode={"CREATE"} addOption={this.addOption} deleteOption={this.deleteOption}></Option>)
        }

        return (

            
            <Col xs={12}  className="question">
                    <Row style={{"position": "relative"}}>
                        <Col xs={12}>
                            <Row>
                                <Col xs={8}>
                                    <Form.Control type="text" className="main col-12" name="main" value={this.state.main} onChange={this.handleChange} placeholder="Question"></Form.Control>
                                </Col>
                                <Col xs={4}>
                                    <Form.Control type="text" className="tag col-12" name="tag" value={this.state.tag} onChange={this.handleChange} placeholder="Tag"></Form.Control>
                                </Col>

                            </Row>
                            <Row>
                                <Col xs={8}>
                                    <Form.Control type="text" className="help_text col-12" name="help_text" value={this.state.help_text} onChange={this.handleChange} placeholder="Help Text"></Form.Control>
                                </Col>
                                <Col xs={4}>
                                    <Form.Control type="text" className="help_text col-12" name="limit" value={this.state.limit} onChange={this.handleChange}></Form.Control>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12">
                            <Row>
                                {OptionsRender}
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Button variant="primary" onClick={this.addNewOptionForm}>Add Option</Button>
                            <Button variant="success" onClick={this.updateQuestion}>Save</Button>
                            <Button variant="danger" onClick={this.deleteQuestion}>Delete</Button>
                        </Col>
                </Row>
            </Col>
        )
    }

    

    renderReadMode = () =>
    {
        let OptionsRender = []
        this.state.options.forEach(option_data => 
            {
                OptionsRender.push(<Option key={option_data._id} data={option_data} addOption={this.addOption} deleteOption={this.deleteOption}></Option>)
            })
        return (
            <Col xs={12} className="question">
                <Row style={{"position": "relative"}}>
                    <Col xs={12}>
                        <Row>
                            <h2 className="col-8 main">{this.state.main}</h2>
                            <h3 className="col-4 tag">#{this.state.tag}</h3>
                        </Row>
                        <Row>
                            <h3 className="col-8 help_text">{this.state.help_text}</h3>
                            <h3 className="col-4 help_text">Limit: {this.state.limit}</h3>
                        </Row>
                    </Col>
                    
                    <Col xs={12}>
                        <Row>
                            {OptionsRender}
                        </Row>
                    </Col>
                    <Col xs={12}>
                        <Button variant="warning" onClick={this.duplicateQuestion}>Duplicate</Button>
                        <Button variant="warning" onClick={this.setEditMode}>Edit</Button>
                        <Button variant="danger" onClick={this.deleteQuestion}>Delete</Button>
                    </Col>
                </Row>
            </Col>

        )
    }

    render()
    {
        let RenderedComponent = <div></div>
        switch(this.state.mode)
        {
            case "READ":
                RenderedComponent = this.renderReadMode()
                break;
            case "EDIT":
                RenderedComponent = this.renderEditMode()
                break;
            case "CREATE":
                RenderedComponent = this.renderCreateMode()
                break;
            case "DUPLICATE":
                RenderedComponent = this.renderDuplicateMode()
                break;
            default:
                RenderedComponent = this.renderReadMode()
                break;
        }

        return (
            RenderedComponent
        )
    }
}

export default withRouter(Question)