import React, { Component } from "react";
import axios from "axios";
import { Container, Col, Row } from "react-bootstrap";
import { ENDPOINT } from "../../secrets";

import ColorLegend from "./ColorLegend";

import {
    XYPlot,
    LineSeries,
    VerticalBarSeries,
    ArcSeries,
    DiscreteColorLegend,
    RadialChart,
} from "react-vis";

class CanvasStats extends Component {
    constructor(props) {
        super(props);

        this.state = {
            canvases: [],
            loading: true,
            canvasQuery: this.props.canvasQuery,
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        const url = `${ENDPOINT}api/statistics/canvas`;
        const query = {};
        axios
            .post(url, query)
            .then((response) => {
                const canvases = response.data.canvases;
                this.setState({
                    canvases: canvases,
                    loading: false,
                });
                console.log(canvases.length);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    convertData = (data) => {
        var newData = [];
        var prev = 0;
        var total = 0;
        data.forEach((item) => {
            total += item.count;
        });
        data.forEach((item) => {
            const angle = prev + (item.count / total) * 2 * Math.PI;
            newData.push({
                angle0: prev,
                angle: angle,
                radius: item.radius,
                radius0: item.radius0,
                color: item.color,
                label: item.label,
                count: item.count,
            });
            prev = angle;
        });
        console.log(prev);
        return newData;
    };

    renderUserChart = (userType) => {
        if (this.state.loading) {
            return <p>Loading...</p>;
        }
        var active_canvas = 0;
        var stagnant_canvas = 0;
        var on_hold_canvas = 0;
        var completed_canvas = 0;
        this.state.canvases.forEach((canvas) => {
            if (!canvas.client) {
                stagnant_canvas++;
            } else if (canvas.stage === "ON_HOLD") {
                on_hold_canvas++;
            } else if (canvas.stage === "COMPLETED") {
                completed_canvas++;
            } else {
                active_canvas++;
            }
        });
        const counts = [
            {
                count: active_canvas,
                radius: 60,
                radius0: 30,
                color: "#00d13c",
                label: "Active",
            },
            {
                count: on_hold_canvas,
                radius: 60,
                radius0: 30,
                color: "#cc3333",
                label: "On Hold",
            },
            {
                count: completed_canvas,
                radius: 60,
                radius0: 30,
                color: "#009999",
                label: "Complete",
            },
        ];

        const data = this.convertData(counts);

        var legend = data.map((item) => {
            return {
                title: item.label,
                color: item.color,
            };
        });
        legend.push({
            title: "Total",
            color: "#000000",
        });
        return (
            <Col>
                <Row className='justify-content-md-center'>
                    <h3>{this.props.title}</h3>
                </Row>
                <Row className='justify-content-md-center'>
                    <Col>
                        {data.map((item, index) => {
                            return (
                                <p key={index}>
                                    {item.label} : {item.count}
                                </p>
                            );
                        })}
                    </Col>
                </Row>
                <Row className='justify-content-md-center'>
                    <XYPlot
                        xDomain={[-5, 5]}
                        yDomain={[-5, 5]}
                        width={200}
                        height={200}
                    >
                        <ArcSeries
                            animation
                            radiusType={"literal"}
                            center={{ x: 0, y: 0 }}
                            data={data}
                            colorType={"literal"}
                        />
                    </XYPlot>
                </Row>
                <Row className='justify-content-md-center'>
                    <ColorLegend items={legend}></ColorLegend>
                </Row>
                <hr />
            </Col>
        );
    };

    render() {
        return (
            <Row
                style={{
                    margin: "10px 30px",
                    padding: "20px 70px",
                    background: "#fff",
                    border: "1px solid #eee",
                }}
                className='justify-content-md-center'
            >
                {this.renderUserChart(this.state.userType)}
            </Row>
        );
    }
}

export default CanvasStats;
