import React, { Component } from 'react'
import {Row, Col, Image} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import {ENDPOINT} from '../../secrets'
import axios from 'axios'
import '../../stylesheets/question.css'

class Option extends Component{
    constructor(props)
    {
        super(props)
        // console.log(props)
        this.state = {
            id: props.data._id,
            text: props.data.text,
            link: props.mode === 'CREATE'? "https://via.placeholder.com/150":props.data.link,
            mode : typeof(props.mode) === 'undefined'? "READ": props.mode,
        }
        this.changePending = false
        this.fileInput= React.createRef()
        
    }

    componentDidMount()
    {
    }

    uploadFile = (event) =>
    {
      event.preventDefault()
      const req = new XMLHttpRequest();
      var file = this.fileInput.current.files[0]
      const formData = new FormData();
      formData.append("file", file, file.name);
      req.responseType = 'json';
      req.open("POST", ENDPOINT + "api/upload");
      req.send(formData);
      // var response = req.response
      // return response.link
      req.onload = (event) => {
        // console.log(req.response)
        this.changePending = true
        this.setState({link: req.response.link})
      }
    }
    
    saveOption = () => 
    {
        if(this.changePending)
        {
            this.changePending  =false
        const data = this.state
        if(this.props.mode === "EDIT")
        {
            axios.put(ENDPOINT + "api/option", data).then(
                response => response.data
            )
            .then(
                response => 
                {
                    // console.log(response)
                }
            )
        }
        if(this.props.mode === "CREATE")
        {
            if(typeof(this.state.id)==='undefined')
            {
                axios.post(ENDPOINT + "api/option", data).then(
                    response => response.data
                )
                .then(
                    response => 
                    {
                        this.props.addOption(response.option._id)
                        this.setState({id: response.id, link: response.option.link, text: response.option.text})
                        // console.log(response)
                    }
                ) 
            }
            else
            {
                axios.put(ENDPOINT + "api/option", data).then(
                    response => response.data
                )
                .then(
                    response => 
                    {
                        // console.log(response)
                    }
                ) 
            }
            
        }
    }
        
    }

    deleteOption = () =>
    {
        axios.delete(ENDPOINT + "api/option", {params: { id: this.state.id }}).then(
            response => response.data
        )
        .then(
            response => 
            {
                // console.log(response)
                if(response.status)
                {
                    this.props.deleteOption(this.state.id)
                }
                // this.setState({id: response.id, link: response.option.link, text: response.option.text})
                // console.log(response)
            }
        ) 
    }

    componentDidUpdate()
    {
        this.saveOption()
    }

    handleChange = (e) =>
    {
        e.preventDefault()
        this.changePending = true
        this.setState({[e.target.name] : e.target.value})
    }

    setEditMode = (e) => 
    {
        this.setState({mode: "EDIT"})
    }

    updateQuestion = (e) => 
    {
        e.preventDefault()
        // console.log(this.state)
        this.setState({mode: "READ"})
    }

    replaceImage = (e) => 
    {

    }

    renderEditMode = () =>
    {
        // console.log("Rendering Edit mode for Option")
        return (
            <Col xs={4}  className="option">
                    <Row style={{"position": "relative"}}>
                        <Col xs={12}>
                            <label htmlFor="image_link">
                                <Image src={this.state.link}  onClick={this.replaceImage} fluid></Image>
                                <input type="file" ref={this.fileInput} onChange={this.uploadFile}></input>
                            </label>
                        </Col>
                        <Col xs={12}>
                            <input type="text" name="text" value={this.state.text} onChange={this.handleChange}></input>
                            <button onClick={this.deleteOption}>Delete</button>
                        </Col>
                </Row>
            </Col>
        )
    }

    renderCreateMode = () =>
    {
        return (
            <Col xs={4}  className="option">
                    <Row style={{"position": "relative"}}>
                        <Col xs={12}>
                            <label htmlFor="image_link">
                                <Image src={this.state.link}  onClick={this.replaceImage} fluid></Image>
                                <input type="file" ref={this.fileInput} onChange={this.uploadFile} style={{opacity: 0}}></input>
                            </label>
                        </Col>
                        <Col xs={12}>
                            <input type="text" name="text" value={this.state.text} onChange={this.handleChange}></input>
                        </Col>
                </Row>
            </Col>
        )
    }

    renderReadMode = () =>
    {
        // console.log(this.props)
        return (
            <Col xs={4}  className="option">
                    <Row style={{"position": "relative"}}>
                        <Col xs={12}>
                            <Image src={this.state.link} fluid></Image>
                        </Col>
                        <Col xs={12}>
                            <p>{this.state.text}</p>
                        </Col>
                </Row>
            </Col>

        )
    }

    


    render()
    {
        let RenderedComponent = <div></div>
        switch(this.props.mode)
        {
            case "READ":
                RenderedComponent = this.renderReadMode()
                break;
            case "EDIT":
                RenderedComponent = this.renderEditMode()
                break;
            case "CREATE":
                RenderedComponent = this.renderCreateMode()
                break;
            default:
                RenderedComponent = this.renderReadMode()
                break;
        }

        return (
            RenderedComponent
        )
    }
}

export default withRouter(Option)