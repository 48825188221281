import React, { Component } from 'react'
import { connect } from 'react-redux'   
import {Container, Row, Col} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import {getAllUsers} from '../../actions/userActions'
import {stages} from '../../actions/canvasActions'
import CanvasRow from './CanvasRow'
import {ENDPOINT} from '../../secrets'
import axios from 'axios'


class CanvasManager extends Component{
    componentDidMount()
    {
        this.props.getAllUsers(null)
    }

    renderImportantCanvases = () =>
    {
        let render = <h6> No paid canvases </h6>
        if(this.props.important_canvases.length > 0)
        {
            render = this.props.important_canvases.map(canvas => <CanvasRow key={canvas._id} data={canvas}></CanvasRow>)
        }
        return render
    }

    renderCanvasWithEmails = () =>
    {
        let render = <h6> No Filled Canvases </h6>
        if(this.props.client_canvases.length > 0 )
        {
            render = this.props.client_canvases.map(canvas => <CanvasRow key={canvas._id} data={canvas}></CanvasRow>)
        }
        return render
    }

    renderCanvasWithOutEmails = () =>
    {
        let render = <h6> No Other Canvases </h6>
        if(this.props.other_canvases.length > 0 )
        {
            render = this.props.other_canvases.map(canvas => <CanvasRow key={canvas._id} data={canvas}></CanvasRow>)
        }
        return render
    }

    renderCanvasRows = () =>
    {
        let render = Object.keys(this.props.canvases).map(id => <CanvasRow key={id} data={this.props.canvases[id]}></CanvasRow>)
        return render
    }

    render()
    {
        return ( 
            <Container id="canvas-manager">
                <Row>
                    <Col xs={12}>
                        <h1 style={{"textAlign":"center"}}>Canvas Manager</h1>
                    </Col>
                    <hr></hr>
                    <Col xs={12}>
                        {/* <h2>Important</h2> */}
                        <Row>
                            {this.renderImportantCanvases()}
                        </Row>
                    </Col>
                    <hr></hr>

                    <Col xs={12}>
                        {/* <h2>Filled Canvases</h2> */}
                        <Row>
                            {this.renderCanvasWithEmails()}
                        </Row>
                    </Col>
                    <hr></hr>

                    <Col xs={12}>
                        {/* <h2>Other Canvases</h2> */}
                        <Row>
                        {this.renderCanvasWithOutEmails()}
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}


const mapStateToProps = (state) => {

    let canvasIds = Object.keys(state.canvases)
    let canvases_with_emails = []
    let canvases_without_emails = []
    let canvases_with_emails_and_transactions = []
    let transactions_keys = Object.keys(state.transactions)

    canvasIds.forEach(id => {
        let canvas = state.canvases[id]
        if(!canvas.deleted)
        {
            if(canvas.client)
            {
                if(transactions_keys.indexOf(canvas._id) < 0)
                {
                    canvases_with_emails.push(canvas)
                }
                else
                {
                    canvases_with_emails_and_transactions.push(canvas)
                }

            }
            else
            {
                canvases_without_emails.push(canvas) 
            }
        }
    });

    // sort by stage 
    let compareStage = (a, b) => stages.indexOf(a.stage) - stages.indexOf(b.stage);

    // let canvases_with_emails = state.canvases.filter(canvas => typeof(canvas.client) != undefined)
    // let canvases_without_emails = state.canvases.filter(canvas => typeof(canvas.client) == undefined)
    // let canvases_with_emails_and_transactions = state.canvases.filter(canvas => typeof(canvas.client) != undefined)
    return { 
        user: state.users,
        canvases: state.canvases,
        important_canvases: canvases_with_emails_and_transactions.sort(compareStage).reverse(),
        client_canvases: canvases_with_emails.sort(compareStage).reverse(),
        other_canvases: canvases_without_emails.sort(compareStage).reverse(),
        transactions: state.transactions
    }
}

const mapDispatchToProps = (dispatch) => {
  
    return { 
        getAllUsers: (id) => dispatch(getAllUsers(id)), 
        // updateCanvas: (data) => dispatch(updateCanvas(data))
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CanvasManager))
