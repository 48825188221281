import React, { Component } from 'react'
import { connect } from 'react-redux'   
import {Container, Row, Col, Button, Image, Table} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import {getAllUsers} from '../../actions/userActions'
import {ENDPOINT} from '../../secrets'
import axios from 'axios'

class UserManager extends Component{
    componentDidMount()
    {
        this.props.getAllUsers(null)
    }

    render()
    {
        return ( 
            <Container>
                <Row>
                    <Col xs={12}>
                        <h1 style={{"textAlign":"center"}}>User Manager</h1>
                    </Col>
                    <Col xs={12}>

                        <h2>Clients</h2>
                        <Table>
                            <thead>
                                <tr>
                                    <td>Number</td>
                                    <td>Name</td>
                                    <td>Email</td>
                                    <td>User Type</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.users && this.props.users.clients && 
                                this.props.users.clients.map((client, i) => <tr>
                                        <td>{i+1}</td>
                                        <td>{client.name}</td>
                                        <td>{client.email}</td>
                                        <td>{client.user_type}</td>
                                    </tr>
                                )
                                }
                            </tbody>
                        </Table>
                    </Col>

                    <Col xs={12}>

                        <h2>Designers</h2>
                        <Table>
                            <thead>
                                <tr>
                                    <td>Number</td>
                                    <td>Name</td>
                                    <td>Email</td>
                                    <td>User Type</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.users && this.props.users.designers && 
                                this.props.users.designers.map((client, i) => <tr>
                                        <td>{i+1}</td>
                                        <td>{client.name}</td>
                                        <td>{client.email}</td>
                                        <td>{client.user_type}</td>
                                    </tr>
                                )
                                }
                            </tbody>
                        </Table>
                    </Col>

                    <Col xs={12}>

                        <h2>Makers</h2>
                        <Table>
                            <thead>
                                <tr>
                                    <td>Number</td>
                                    <td>Name</td>
                                    <td>Email</td>
                                    <td>User Type</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.users && this.props.users.fabricators && 
                                this.props.users.fabricators.map((client, i) => <tr>
                                        <td>{i+1}</td>
                                        <td>{client.name}</td>
                                        <td>{client.email}</td>
                                        <td>{client.user_type}</td>
                                    </tr>
                                )
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        )
    }
}


const mapStateToProps = (state) => {
    return { 
        users: state.users
    }
}

const mapDispatchToProps = (dispatch) => {
  
    return { 
        getAllUsers: (id) => dispatch(getAllUsers(id)), 
        // updateCanvas: (data) => dispatch(updateCanvas(data))
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserManager))
