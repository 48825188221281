const initUsers = {
}

const DEBUG = false

const userReducer = (state = initUsers, action) => {
    let newState

    if(DEBUG) console.log("USER REDUCER:")
    if(DEBUG) console.log("ACTION:")
    if(DEBUG) console.log(action)

    if(DEBUG) console.log("CURRENT STATE:")
    if(DEBUG) console.log(state)

    switch(action.type)
    {
        case "ADD_USER":
            newState = {...state, [action.id]: action.user}
            break
        case "UPDATE_USER":
            newState = {...state, [action.id]: action.user}
            break
        case "ADD_ALL_USERS":
            newState = {...state, "designers": action.designers, "fabricators": action.fabricators, "clients": action.clients}
            break
        default:
            newState = state
    }

    if(DEBUG) console.log("NEW STATE:")
    if(DEBUG) console.log(newState)

    return newState
}

export default userReducer