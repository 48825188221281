import React, { Component } from 'react'
import { connect } from 'react-redux'   
import {Container, Row, Col} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import {getAllUsers} from '../../actions/userActions'
import CanvasRow from './CanvasRow'

class CompetitionManager extends Component{
    componentDidMount()
    {
        this.props.getAllUsers(null)
    }

    renderCanvasWithEmails = () =>
    {
        let render = <h6> No Filled Canvases </h6>
        if(this.props.designer_canvases.length > 0 )
        {
            render = this.props.designer_canvases.map(canvas => <CanvasRow key={canvas._id} data={canvas}></CanvasRow>)
        }
        return render
    }

    renderCoDesignCanvases = () =>
    {
        let render = <h6> No Filled Canvases </h6>
        if(this.props.codesign_canvases.length > 0 )
        {
            render = this.props.codesign_canvases.map(canvas => <CanvasRow key={canvas._id} data={canvas}></CanvasRow>)
        }
        return render
    }

    renderCanvasRows = () =>
    {
        let render = Object.keys(this.props.canvases).map(id => <CanvasRow key={id} data={this.props.canvases[id]}></CanvasRow>)
        return render
    }

    render()
    {
        return ( 
            <Container>
                <Row>
                    <Col xs={12}>
                        <h1 style={{"textAlign":"center"}}>Competition Manager</h1>
                    </Col>
                    <Col xs={12}>
                        <h2>CoDesign Track</h2>
                        {this.renderCoDesignCanvases()}
                        <h2>Designer Track</h2>
                        {this.renderCanvasWithEmails()}
                        
                    </Col>
                </Row>
            </Container>
        )
    }
}


const mapStateToProps = (state) => {

    let canvasIds = Object.keys(state.canvases)
    let canvases_with_emails = []
    let codesign_track = []
    let designer_track = []


    canvasIds.forEach(id => {
        let canvas = state.canvases[id]
        if(!canvas.deleted)
        {
            if(canvas.pathway === "COMPETITION")
            {
                if(canvas.client)
                {
                    
                    if(canvas.track === "CO_DESIGN")
                    {
                        codesign_track.push(canvas)
                    }
                    else{
                        canvases_with_emails.push(canvas)
                        designer_track.push(canvas)
                    }

                }
            }
        }
    });
    // let canvases_with_emails = state.canvases.filter(canvas => typeof(canvas.client) != undefined)
    // let canvases_without_emails = state.canvases.filter(canvas => typeof(canvas.client) == undefined)
    // let canvases_with_emails_and_transactions = state.canvases.filter(canvas => typeof(canvas.client) != undefined)
    return { 
        user: state.users,
        canvases: state.canvases,
        client_canvases: canvases_with_emails,
        codesign_canvases: codesign_track,
        designer_canvases: designer_track,
        transactions: state.transactions
    }
}

const mapDispatchToProps = (dispatch) => {
  
    return { 
        getAllUsers: (id) => dispatch(getAllUsers(id)), 
        // updateCanvas: (data) => dispatch(updateCanvas(data))
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompetitionManager))
