import {ENDPOINT} from '../secrets'
import axios from 'axios'

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    },
        crossDomain: true
  }

  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';


  export const initiatePayment = (payload) => 
  {
      return (dispatch) => {
          const PAY_FEES = `${ENDPOINT}payments/client`                        
          dispatch({type: 'SET_LOADING', key: "transactionProcessing", value:true})

          axios.post(PAY_FEES, payload, config)
              .then(response => 
                  {
                      response = response.data
                      if(response.status)
                      {

                          dispatch(getTransactionsForCanvas(response.transaction.canvas._id))
                          dispatch(getTransactionsForUser(response.transaction.user._id))
                      }   
                      else
                      {
                        dispatch({type: 'TRANSACTION_ERROR', message: response.message})
                      }

                      dispatch({type: 'SET_LOADING', key: "transactionProcessing", value:false})

                  })
                  .catch(e => {
                    dispatch({type: 'TRANSACTION_ERROR', message: "Something went wrong. Check your internet connection."})
                    dispatch({type: 'SET_LOADING', key: "transactionProcessing", value:false})

                })
      }
  }

export const getTransactionsForCanvas = (id) => 
{
    return (dispatch) => {
        const GET_CANVAS_TRANSACTIONS = `${ENDPOINT}payments/canvas`
        axios.get(GET_CANVAS_TRANSACTIONS, {params: {id: id}}, config)
            .then(response => 
                {
                    // console.log(response)
                    response = response.data
                    if(response.status)
                    {
                        dispatch({type: 'ADD_TRANSACTIONS', id: id, transactions: response.transactions})
                    }   
                    else
                    {
                        dispatch({type: 'ADD_TRANSACTIONS_ERROR'})
                    }
                })
                .catch(e => {
                    dispatch({type: 'ADD_TRANSACTIONS_ERROR'})
                })
    }
}


export const getTransactionsForUser = (id) => 
{
    return (dispatch) => {
        const GET_CANVAS_TRANSACTIONS = `${ENDPOINT}payments/user`
        axios.get(GET_CANVAS_TRANSACTIONS, {params: {id: id}}, config)
            .then(response => 
                {
                    response = response.data
                    if(response.status)
                    {
                        dispatch({type: 'ADD_TRANSACTIONS', id: id, transactions: response.transactions})
                    }   
                    else
                    {
                        dispatch({type: 'ADD_TRANSACTIONS_ERROR'})
                    }
                })
                .catch(e => {
                    dispatch({type: 'ADD_TRANSACTIONS_ERROR'})
                })
    }
}