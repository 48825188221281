import {combineReducers} from 'redux'
import canvasReducer from "./canvasReducer";
import userReducer from "./userReducer";
import errorsReducer from "./errorsReducer";
import loadingReducer from "./loadingReducer";
import transactionReducer from './transactionsReducer'
import ApprovalCommentsResucer from './approvalCommentsReducer'

const rootReducer = combineReducers({
            canvases: canvasReducer, 
            users: userReducer, 
            errors: errorsReducer, 
            loading: loadingReducer, 
            approval_comments:ApprovalCommentsResucer,
        transactions: transactionReducer})

export default rootReducer