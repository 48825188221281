import React from 'react';
import { connect } from 'react-redux'   
import {Container, Row, Col} from 'react-bootstrap';
import {getComments} from '../../actions/commentsActions'
import '../../stylesheets/approvalComments.css';
import { withRouter } from 'react-router-dom';
import CommentTile from './CommentTile';

class Comments extends React.Component{

  state = {
    comments : this.props.approval_comments
  }
  componentDidMount(){
    this.props.getComments();
  }

  renderComments = () => {
    if(this.props.approval_comments.length===0){
      return(
        <h6>No approval comments pending!</h6>
      )
    }
    else{
      return(
        <>
        {
          this.props.approval_comments.map((comment, index) => (
            <CommentTile 
              key={index}
              data={comment}
            />
          ))
        }
        </>
      )
    }
  }

  render(){
    return(
      <Container id="comments">
        <h3 style={{textAlign:'center', fontFamily:'bigmoore', fontWeight:'bold'}}>COMMENTS MANAGER</h3>
        <hr/>
        {this.renderComments()}
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return { 
      approval_comments:state.approval_comments
  }
}

const mapDispatchToProps = (dispatch) => {

  return { 
    getComments: () => dispatch(getComments()), 
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Comments))