let ENDPOINT;
let DEBUG;
let FRONTEND;
let PROJECT_BACKEND_ENDPOINT;

const GOOGLE_MAPS_API_KEY = "AIzaSyBE5iN0SLeAwuVgY5-kwd9l7ByQFWVT8ys";

console.log(process.env.REACT_APP_ENV)

if (process.env.REACT_APP_STAGING) {
    DEBUG = false;
    ENDPOINT = "//stg-api.naya.studio/";
    FRONTEND = "//stg-app.naya.studio/";
    PROJECT_BACKEND_ENDPOINT = "https://naya-project-backend-staging-ziylmcndpq-ue.a.run.app"
} else if (process.env.REACT_APP_ENV === "production") {
    DEBUG = false;
    FRONTEND = "//app.naya.studio";
    ENDPOINT = "//api.naya.studio/";
} else {

    PROJECT_BACKEND_ENDPOINT = "https://naya-project-backend-development-ziylmcndpq-ue.a.run.app"
    // ENDPOINT = "http://localhost:3005/";
    // FRONTEND = "http://localhost:3000";
    ENDPOINT = "https://stg-api.naya.studio/";
    FRONTEND = "https://stg-app.naya.studio/";

    DEBUG = true;
}

export { ENDPOINT, DEBUG, FRONTEND, GOOGLE_MAPS_API_KEY, PROJECT_BACKEND_ENDPOINT };
