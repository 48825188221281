import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";

/**
 * TextQuestion component used to create and display TEXT questions
 */
class TextQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                title: "",
                desc: "",
                answer_type: "TEXT",
                mapping_field: "",
                sample_options: [],
                tag: "",
            },
            newSampleOption: "",
            display: this.props.display,
            mode: this.props.mode,
        };
    }

    handleNewOptionChange = (e) => {
        this.setState({
            newSampleOption: e.target.value
        })
    }

    handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            form: {
                ...this.state.form,
                [name]: value,
            },
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.handleSubmit(this.state.form);
    };

    addNewOption = (e) => {
        e.preventDefault();

        let {form, newSampleOption} = this.state;
        form.sample_options.push(newSampleOption)
        this.setState({
            form,
            newSampleOption: ""
        })
    }

    renderSampleOptions = () => {
        var options = this.state.form.sample_options;
        let render = [];
        render.push(<p>Sample Options</p>)
        if (options.length <= 0) render.push(<p>---Nothing Added Yet---</p>);
        options.forEach((option) => {
            render.push(
                <li key={this.index++}>
                    {option}
                </li>
            );
        });

        return render;
    }

    renderEdit = () => {
        return (
            <Form onSubmit={this.handleSubmit}>
                <label>Title</label>
                <input
                    name="title"
                    type="text"
                    onChange={this.handleInputChange}
                />
                <br />
                <label>Description</label>
                <input
                    name="desc"
                    type="text"
                    onChange={this.handleInputChange}
                />
                <br />
                <label>Mapping Field</label>
                <input
                    name="mapping_field"
                    type="text"
                    onChange={this.handleInputChange}
                />
                <br />
                {this.renderSampleOptions()}
                <label>Add sample option</label>
                <input 
                    name="new_sample_option"
                    type="text"
                    value={this.state.newSampleOption}
                    onChange={this.handleNewOptionChange} 
                />
                <button onClick={this.addNewOption}>Add</button>
                <br />
                <label>Tag</label>
                <input
                    name="tag"
                    type="text"
                    onChange={this.handleInputChange}
                />
                <br />
                <input type="submit" value="Add" />
            </Form>
        );
    };

    renderDisplay = () => {
        var render = [];
        var key = 0;
        render.push(<h1 key={key++}>{this.state.display.title}</h1>);
        render.push(<h2 key={key++}>{this.state.display.desc}</h2>);
        render.push(
            <p key={key++}>
                <b>Type: </b> {this.state.display.answer_type}
            </p>
        );
        render.push(
            <p key={key++}>
                <b>Mapping field:</b> {this.state.display.mapping_field}
            </p>
        );

        if(this.state.display.sample_options){
            render.push(
                <p key={key++}>
                    <b>Sample Options: {this.state.display.sample_options.join(", ") || "None"}</b>
                </p>
            )
        }

        render.push(
            <h5 key={key++}>
                <b>Tag:</b> {this.state.display.tag}
            </h5>
        );
        render.push(<hr key={key++} />);
        return render;
    };

    render() {
        let render = null;
        if (this.state.mode === "DISPLAY") render = this.renderDisplay();
        else if (this.state.mode === "EDIT") render = this.renderEdit();
        return (
            <Container>
                <Row>
                    <Col
                        style={{
                            backgroundColor: "#eeeeee",
                            margin: "20px",
                        }}
                    >
                        {render}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default TextQuestion;
