import React, { Component } from 'react'
import { connect } from 'react-redux'   
import {Row, Col, Button, Image, Modal} from 'react-bootstrap'
import {connectUserCanvas} from '../../actions/userActions'
import {updateCanvas, postTrigger,  stages} from '../../actions/canvasActions'
import { withRouter } from 'react-router-dom'
import '../../stylesheets/canvas.css'
import cancel from '../../images/cancel.svg'
import check from '../../images/selected.svg'
import {FRONTEND} from '../../secrets'
import placeholder_profile_pic from "../../images/placeholder_profile_pic.png"
import add from "../../images/add.png"

import "../../stylesheets/canvas_row.css"
class CanvasRow extends Component{
    constructor(props)
    {
        super(props)

        let stage_history = props.data.stage_history.map(s => s.stage)
        let currentStage = props.data.stage ? props.data.stage : (stage_history ? stage_history[stage_history.length - 1] : "")
        
        let allStages = stages.indexOf(currentStage) != -1 ? stages : [currentStage, ...stages]
        

        this.state = {
            showSetDesigner: false,
            showSetFabricationConsultant: false,
            showSetFabricator: false,
            show: false,
            designerValue: 'NONE',
            consultantValue: 'NONE',
            fabricatorValue: 'NONE',
            confirmDeleteCanvas: false,
            stage: currentStage,
            stages: allStages,
            updateStatus: false,
            updateError: false
        }
    }

    setDesigner = (e) =>
    {
        this.setState({showSetDesigner: true})
        e.preventDefault()
    }

    setFabricationConsultant = (e) =>
    {
        this.setState({showSetFabricationConsultant: true})
        e.preventDefault()

    }

    setFabricator = (e) =>
    {
        this.setState({showSetFabricator: true})
        e.preventDefault()

    }

    timeDifference = (current, previous) => {

        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;
    
        var elapsed = current - previous;
    
        if (elapsed < msPerMinute) {
             return Math.round(elapsed/1000) + ' seconds ago';   
        }
    
        else if (elapsed < msPerHour) {
             return Math.round(elapsed/msPerMinute) + ' mins ago';   
        }
    
        else if (elapsed < msPerDay ) {
             return Math.round(elapsed/msPerHour ) + ' h ago';   
        }
    
        else if (elapsed < msPerMonth) {
            return Math.round(elapsed/msPerDay) + ' days ago';   
        }
    
        else if (elapsed < msPerYear) {
            return  Math.round(elapsed/msPerMonth) + ' months ago';   
        }
    
        else {
            return Math.round(elapsed/msPerYear ) + ' y ago';   
        }
    }

    handleClose = (e) =>
    {
        this.setState({[e]: false})
    }

    handleChange = (e, name) =>
    {
        this.setState({[`${name}Value`]:e.target.value})
    }

    handleConnectUser = (connectionType) =>
    {
        let data = {
            canvasId        : this.props.data._id,
            connectionType  : connectionType
        }
        switch(connectionType)
        {
            case "DESIGNER":
                data.userId     = this.state.designerValue
                data.prevUserId = this.props.data.designer? this.props.data.designer._id: "NONE"
                this.props.connectUserCanvas(data)
                this.setState({"showSetDesigner": false})
                break
            case "CONSULTANT":
                data.userId     = this.state.consultantValue
                data.prevUserId = this.props.data.consultant? this.props.data.consultant._id: "NONE"
                this.props.connectUserCanvas(data)
                this.setState({"showSetFabricationConsultant": false})
                break
            case "FABRICATOR":
                data.userId     = this.state.fabricatorValue
                data.prevUserId = this.props.data.fabricator? this.props.data.fabricator._id: "NONE"
                this.props.connectUserCanvas(data)
                this.setState({"showSetFabricator": false})
                break
            default:
                break
        }
    }

    deleteCanvas = () =>
    {
        this.props.updateCanvas({
            deleted: true,
            id: this.props.data._id
        })
    }

    updateStage = () => {

        postTrigger({
            triggerType: this.state.stage,
            payload: this.props.data._id,
            data: {
                user_id: this.props.data.client._id,
                canvas_id: this.props.data._id
            }
        }).then(() => {
            this.setState({
                updateStatus: true
            }, () => {
                setTimeout(this.clearUpdateMessage, 5000)
            })
        })
        .catch(() => {
            this.setState({
                updateStatus: true,
                updateError: true
            }, () => {
                setTimeout(this.clearUpdateMessage, 5000)
            })
        })
    }

    clearUpdateMessage = () => {
        this.setState({
            updateStatus: false,
            updateError: false
        })
    }

    renderTransactions()
    {
        let transactions = this.props.transactions[this.props.data._id]
        let DesignerFee = false        
        let FabricationFee = false     
        let DesignerExtraFee = false
        // console.log(this.props.transactions)

        let totalAmount = 0
        if(transactions)
        {
            DesignerFee        = transactions.filter(transaction => transaction.description === "DESIGNER_FEE")
            if(DesignerFee.length>0)
            {
                totalAmount += DesignerFee[0].amount
            }

            FabricationFee      = transactions.filter(transaction => transaction.description === "FABRICATION_FEE")
            if(FabricationFee.length>0)
            {
                totalAmount += FabricationFee[0].amount
            }

            DesignerExtraFee    = transactions.filter(transaction => transaction.description === "DESIGNER_EXTRA_FEE").length>0    
            if(DesignerExtraFee.length >0)
            {
                totalAmount += DesignerExtraFee[0].amount
            }
        }
        
        return (
            <Row>
                <Col xs={12} lg={{span:6}}>
                    <Row>
                        <Col xs={12}>
                            <h5>{"$" + totalAmount/100}</h5>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    handleSelect = (e) => {
        this.setState({
            stage: e.target.value
        })
    }

    render()
    {
        return ( <>
            <Col xs={12} lg={{span:4}} >
                <Col className={"canvas-row"}>
                <Row>
                    <Col xs={12} lg={12}>
                        <Row>
                            <Col xs={12} style={{"cursor":"pointer"}} onClick={() => window.location = `${FRONTEND}/canvas/${this.props.data._id}`}>
                                <h3 style={{"overflow":"hidden", "text-overflow":"hidden"}}>
                                    {this.props.data.name || this.props.data._id}
                                </h3>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={{span:12}}>
                        <Row>
                            <Col xs={12}>

                                <p>{this.timeDifference(new Date(), new Date(this.props.data.updatedAt))}</p>                    
                            </Col>
                        </Row>
                    </Col>
                    
                    </Row>
                    {this.renderTransactions()}
                    <Row>

                    <Col xs={3} lg={3}>
                        <Row>

                        <Col xs={12}>
                            <p style={{"textAlign":"center"}}>U</p>
                        </Col>
                        <Col xs={12} value={"showSetDesigner"} onClick={this.setDesigner}>

                        <Col className={"profile-pic"}>
                                {this.props.data.client && this.props.data.client.profile_pic && this.props.data.client.email && <img title={this.props.data.client.email.split("@")[0]} src={this.props.data.client.profile_pic}></img>}
                                {this.props.data.client && !this.props.data.client.profile_pic && this.props.data.client.email && <img title={this.props.data.client.email.split("@")[0]} src={placeholder_profile_pic}></img>}
                                {!this.props.data.client && <img src={add}></img>}
                            </Col>
                            </Col>
                        </Row>
                    </Col>
                    
                    <Col xs={3} lg={3}>
                        <Row>
                        <Col xs={12}>
                            <p style={{"textAlign":"center"}}>
                                D                        
                            </p>
                                
                            </Col>
                        <Col xs={12} value={"showSetDesigner"} onClick={this.setDesigner}>
                            <Col className={"profile-pic"}>
                                {this.props.data.designer && this.props.data.designer.profile_pic && <img title={this.props.data.designer.email.split("@")[0]} src={this.props.data.designer.profile_pic}></img>}
                                {this.props.data.designer && !this.props.data.designer.profile_pic && <img title={this.props.data.designer.email.split("@")[0]} src={placeholder_profile_pic}></img>}
                                {!this.props.data.designer && <img src={add}></img>}
                            </Col>
                        </Col>
                        </Row>                
                        </Col>
                    
                    <Col xs={3} lg={3} value={"showSetFabricationConsultant"} onClick={this.setFabricationConsultant}>
                        <Row>
                        <Col xs={12}>
                        <p style={{"textAlign":"center"}}>
                                C
                            </p>
                        </Col>
                        <Col xs={12}>
                        <Col className={"profile-pic"}>
                            {this.props.data.consultant && this.props.data.consultant.profile_pic && <img title={this.props.data.consultant.email.split("@")[0]} src={this.props.data.consultant.profile_pic}></img>}
                            {this.props.data.consultant && !this.props.data.consultant.profile_pic && <img title={this.props.data.consultant.email.split("@")[0]} src={placeholder_profile_pic}></img>}
                            {!this.props.data.consultant && <img src={add}></img>}
                            </Col>

                        </Col>
                        </Row>                
                        </Col>
                    <Col xs={3} lg={3} value={"showSetFabricator"} onClick={this.setFabricator}>
                        <Row>
                        <Col xs={12}>
                        <p style={{"textAlign":"center"}}>
                                M
                            </p>
                        </Col>
                        <Col xs={12}>
                        <Col className={"profile-pic"}>

                            {this.props.data.fabricator && this.props.data.fabricator.profile_pic && <img title={this.props.data.fabricator.email.split("@")[0]} src={this.props.data.fabricator.profile_pic}></img>}
                            {this.props.data.fabricator && !this.props.data.fabricator.profile_pic && <img title={this.props.data.fabricator.email.split("@")[0]} src={placeholder_profile_pic}></img>}
                            {!this.props.data.fabricator && <img src={add}></img>}
                            </Col>
                        </Col>
                        </Row>                
                    </Col>

                    <Col xs={12} lg={12}>
                        <Row>
                        <Col xs={12} >
                            <p style={{"text-overflow":"ellipsis", "overflow":"hidden", "marginTop":"1rem", "color":"transparent"}}>
                                {/* People Involved:  */}
                                <span>
                                    {this.props.data.client && this.props.data.client.email && this.props.data.client.email.split("@")[0] + ","}
                                    {this.props.data.designer && this.props.data.designer.email.split("@")[0] + ","}
                                    {this.props.data.consultant && this.props.data.consultant.email.split("@")[0] + ","}
                                    {this.props.data.fabricator && this.props.data.fabricator.email.split("@")[0]}
                                </span>
                            </p>
                        </Col>
                        </Row>                
                    </Col>

                    <Col xs={12} lg={{span:12}}>
                        <Row>
                                {this.props.data.deleted && 
                                <Col xs={12}>
                                    Deleted
                                </Col>
                                }
                                {!this.props.data.deleted && this.state.confirmDeleteCanvas && 
                                <Col xs={12}>
                                    <Row>
                                        <Col xs={12}>
                                            Are you sure?
                                        </Col>
                                        <Col xs={6}>
                                            <Button className="col-10" onClick={this.deleteCanvas}>Yes</Button>
                                        </Col>
                                        <Col xs={6}>
                                            <Button className="col-10" onClick={() => {this.setState({confirmDeleteCanvas: false})}}>No</Button>
                                        </Col>
                                    </Row>
                                </Col>
                                }
                                {!this.props.data.deleted && !this.state.confirmDeleteCanvas && 
                                <Col xs={12}>
                                    <Button onClick={() => {this.setState({confirmDeleteCanvas: true})}}>Delete</Button>
                                </Col>
                                }
                        </Row>
                    </Col>
                    
                </Row>
                <Row style={{marginTop: "5px"}}>
                    <Col>
                    <Row>
                        <Col xs={12}>
                            <h6>
                                Stage
                            </h6>
                        </Col>
                        <Col xs={12}>
                            <select
                                value={this.state.stage}
                                onChange={this.handleSelect}>
                                {this.state.stages.map(s => <option key={s} value={s}>
                                        {s}
                                    </option>
                                )}
                            </select>
                            <Button onClick={() => this.updateStage()}>Update</Button>
                            {this.state.updateStatus ? (this.state.updateError ? <p>Error!</p> : <p>Update success!</p>) : null}
                        </Col>
                        </Row> 
                    </Col>
                </Row>
            </Col>
            <Modal show={this.state.showSetDesigner} onHide={() => this.handleClose("showSetDesigner")}>
                <Modal.Header closeButton>
                <Modal.Title>Set Designer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <select value={this.state.designerValue} onChange={(e) => {this.handleChange(e, 'designer')}}>
                        {this.props.designers && this.props.designers.map(designer => <option key={designer._id} value={designer._id}>{designer.email}</option>)}
                        <option value="NONE">None</option>
                    </select>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => this.handleClose("showSetDesigner")}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => this.handleConnectUser("DESIGNER")}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={this.state.showSetFabricationConsultant} onHide={() => this.handleClose("showSetFabricationConsultant")}>
                <Modal.Header closeButton>
                <Modal.Title>Set Fabrication Consultant</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <select value={this.state.consultantValue} onChange={(e) => {this.handleChange(e, 'consultant')}}>
                        {this.props.fabricators && this.props.fabricators.map(fabricator => <option key={fabricator._id} value={fabricator._id}>{fabricator.email}</option>)}
                        <option value="NONE">None</option>
                    </select>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => this.handleClose("showSetFabricationConsultant")}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => this.handleConnectUser("CONSULTANT")}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={this.state.showSetFabricator} onHide={(e) => this.handleClose("showSetFabricator")}>
                <Modal.Header closeButton>
                <Modal.Title>Set Fabricator</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <select value={this.state.fabricatorValue} onChange={(e) => {this.handleChange(e, 'fabricator')}}>
                        {this.props.fabricators && this.props.fabricators.map(fabricator => <option key={fabricator._id} value={fabricator._id}>{fabricator.email}</option>)}
                        <option value="NONE">None</option>
                    </select>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => this.handleClose("showSetFabricator")}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => this.handleConnectUser("FABRICATOR")}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>

            </Col>
            </>
        )
    }
}

const mapStateToProps = (state) => {

    return { 
        transactions: state.transactions,
        designers: state.users.designers,
        fabricators: state.users.fabricators
    }
}

const mapDispatchToProps = (dispatch) => {
  
    return { 
        connectUserCanvas: (data) => dispatch(connectUserCanvas(data)), 
        updateCanvas: (data) => dispatch(updateCanvas(data))
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CanvasRow))
