const initTransactions = {
}

const DEBUG = false
const canvasReducer = (state = initTransactions, action) => {
    let newState

    if(DEBUG) console.log("TRANSACTIONS REDUCER:")
    if(DEBUG) console.log("ACTION:")
    if(DEBUG) console.log(action)

    if(DEBUG) console.log("CURRENT STATE:")
    if(DEBUG) console.log(state)

    switch(action.type)
    {
        case "ADD_CANVAS":
            break
        case "UPDATE_CANVAS":
            break
        case "ADD_ALL_CANVASES":
            newState = state
            
            break
        case 'ADD_TRANSACTIONS':
            newState = {...state, [action.id]: action.transactions}
            break;
        default:
            newState = state
    }

    if(DEBUG) console.log("NEW STATE:")
    if(DEBUG) console.log(newState)

    return newState
}

export default canvasReducer