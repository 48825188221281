import axios from 'axios'
import {ENDPOINT, PROJECT_BACKEND_ENDPOINT} from '../secrets'
import {getTransactionsForCanvas} from './transactionActions'
const GET_ALL_CANVAS_URI = `${ENDPOINT}api/canvases`
const UPDATE_CANVAS_URI = `${ENDPOINT}api/canvas`

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    
    }
  }

  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const getAllCanvases = (id) => 
{
    return (dispatch) => {
        let params = {
            type: "ALL"
        }
        axios.get(GET_ALL_CANVAS_URI,  {params: params}, config)
            .then(response => 
                {
                    response = response.data
                    if(response.status)
                    {
                        response.canvases.forEach(canvas => {
                            dispatch(getTransactionsForCanvas(canvas._id))
                        });
                        dispatch({type: "ADD_ALL_CANVASES", canvases: response.canvases})
                    }
                })
    }
}

export const updateCanvas = (data) => 
{
    return (dispatch) => {
        axios.put(UPDATE_CANVAS_URI,  data, config)
            .then(response => 
                {
                    response = response.data
                    if(response.status)
                    {
                        dispatch(getAllCanvases())
                    }
                })
    }
}

export const postTrigger = (trigger) => {
    console.log("Creating trigger...");
    console.log(trigger);
    return axios
        .post(
            `${PROJECT_BACKEND_ENDPOINT}/trigger`,
            { trigger: trigger },
            {
                crossDomain: true,
            }
        )
        .then((response) => {
            console.log(`Inserted at - ${response.data["insertedId"]}`);
            return Promise.resolve()
        })
        .catch((e) => {
            console.log("Error creating trigger");
            console.log(e);
            return Promise.reject()
        });
};

export const stages = ["PRE_EMAIL_ONBOARDING", "POST_EMAIL_ONBOARDING", "CANVAS_COMPLETE", "DESIGN_STAGE_BYPASS_REQUEST", "DESIGN_FEE_PAID", "DESIGNER_ASSIGNED", "IDEA_CLARIFICATIONS", "SKETCH_PHASE", "WHITE_MODEL_PHASE", "MCF", "CONSULTANT_ASSIGNED", "FABRICATION_CONSULTING", "READY_FOR_TENDER", "FABRICATOR_MATCHMAKING", "FABRICATOR_ASSIGNED", "FABRICATION", "QUALITY_CONTROL", "READY_TO_SHIP", "SHIPPED", "DELIVERED", "BUFFER", "ON_HOLD", "COMPLETE"]
