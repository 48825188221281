import axios from 'axios'
import {ENDPOINT} from '../secrets'

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    
    }
  }

  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

  export const getComments = () => {
    return (dispatch) => {
        //Create Answer
        const GET_COMMENTS = `${ENDPOINT}api/approval-comments`;

        axios
            .get(GET_COMMENTS)
            .then((response) => {
                // console.log("Comment approved:", response.data)
                if (response.data.status) {
                    dispatch({type:'SET_APPROVAL_COMMENTS', approval_comments: response.data.comments})
                } 
            })
            .catch((e) => {
                dispatch({
                    type: "ERROR_POST_COMMENT",
                    message:
                        "Our systems are experiencing issues. Please try again after some time",
                });
            });
    };
};

export const declineComment = (data) => {
    console.log("declining comment:", data)
    return (dispatch) => {
        const DELETE_COMMENT = `${ENDPOINT}api/approval-comments`;

        axios
            .delete(DELETE_COMMENT, {params:data}, config)
            .then((response) => {
                console.log("Comment declined:", response.data)
                if (response.data.status) {
                    console.log("Comment approval declined!")
                    dispatch(getComments());
                } 
            })
            .catch((e) => {
                dispatch({
                    type: "ERROR_POST_COMMENT",
                    message:
                        "Our systems are experiencing issues. Please try again after some time",
                });
            });
    };
};

export const approveComment = (data) => {
    return (dispatch) => {
        const APPROVE_COMMENT = `${ENDPOINT}api/approval-comments`;

        axios
            .put(APPROVE_COMMENT, data, config)
            .then((response) => {
                if (response.data.status) {
                    console.log("Comment approved!", response.data.comment)
                    let commentData = response.data.comment;
                    commentData["canvas_id"]=data.canvas_id;
                    dispatch(postComment(commentData));
                    dispatch(declineComment({id:data.id}))
                    // dispatch(getCanvas());
                } 
            })
            .catch((e) => {
                dispatch({
                    type: "ERROR_POST_COMMENT",
                    message:
                        "Our systems are experiencing issues. Please try again after some time",
                });
            });
    };
};

export const postComment = (data) => 
{

    return (dispatch) => {
        //Create Answer
        const CREATE_COMMENT_DATA_URI = `${ENDPOINT}api/comments`

        axios.post(CREATE_COMMENT_DATA_URI, data, config)
            .then(response => {
                console.log("comment posted",response)
            }).catch(e => {
                console.log(e)
                dispatch({type: "ERROR_POST_COMMENT", message:"Our systems are experiencing issues. Please try again after some time"})
            })
    }
}