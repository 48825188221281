import React, { Component } from 'react'
import { connect } from 'react-redux'   
import {Container, Row, Col} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import {Elements} from 'react-stripe-elements';
import InjectedCheckoutForm from './CheckoutForm';
class TransactionManager extends Component{


    render()
    {
        return ( 
            <Container>
                <Row>
                    <Col xs={12}>
                    <Elements>
                        <InjectedCheckoutForm />
                    </Elements>
                    </Col>
                </Row>
            </Container>
        )
    }
}


const mapStateToProps = (state) => {
    return { 
        user: state.users,
        canvases: state.canvases
    }
}

const mapDispatchToProps = (dispatch) => {
  
    return { 
        // getCanvas: (id) => dispatch(getCanvas(id)), 
        // updateCanvas: (data) => dispatch(updateCanvas(data))
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TransactionManager))
