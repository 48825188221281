import axios from 'axios'
import {ENDPOINT} from '../secrets'
import {getAllCanvases} from './canvasActions'

const GET_ALL_USERS_URI = `${ENDPOINT}users`
const CONNECT_USER_CANVAS = `${ENDPOINT}users/connect-user-canvas`

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    
    }
  }

  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const getAllUsers = (id) => 
{
    return (dispatch) => {
        
        axios.get(GET_ALL_USERS_URI, config)
            .then(response => 
                {
                    response = response.data
                    console.log(response)
                    if(response.status)
                    {
                        dispatch({type: "ADD_ALL_USERS", designers: response.designers, fabricators: response.fabricators, clients: response.clients})
                    }
                })
    }
}

export const connectUserCanvas = (data) => 
{
    return (dispatch) => {
        axios.post(CONNECT_USER_CANVAS, data, config)
            .then(response => 
            {
                console.log(response)
                response = response.data
                if(response.status)
                {
                    dispatch(getAllCanvases(null))
                }
            })
    }
}
