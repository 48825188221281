import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";

/**
 * NumberQuestion component used to create and display Number questions
 */
class NumberQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                title: "",
                desc: "",
                answer_type: "NUMBER",
                mapping_field: "",
                tag: "",
            },
            display: this.props.display,
            mode: this.props.mode,
        };
    }

    handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            form: {
                ...this.state.form,
                [name]: value,
            },
        });
    };

    handleUnitChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            form: {
                ...this.state.form,
                question_number_range: {
                    ...this.state.form.question_number_range,
                    [name]: value,
                },
            },
        });
    };

    handleNumberRange = (e) => {
        const name = e.target.name;
        var value = e.target.value;
        if (value === "") value = 0;
        else value = Number(value);
        this.setState({
            form: {
                ...this.state.form,
                question_number_range: {
                    ...this.state.form.question_number_range,
                    [name]: value,
                },
            },
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.handleSubmit(this.state.form);
    };

    renderEdit = () => {
        return (
            <Form onSubmit={this.handleSubmit}>
                <label>Title</label>
                <input
                    name="title"
                    type="text"
                    onChange={this.handleInputChange}
                />
                <br />
                <label>Description</label>
                <input
                    name="desc"
                    type="text"
                    onChange={this.handleInputChange}
                />
                <br />
                <label>Unit</label>
                <input
                    name="unit"
                    type="text"
                    onChange={this.handleUnitChange}
                />
                <br />
                <label>Low: </label>
                <input
                    name="low"
                    type="number"
                    onChange={this.handleNumberRange}
                />
                <br />
                <label>High: </label>
                <input
                    name="high"
                    type="number"
                    onChange={this.handleNumberRange}
                />
                <br />
                <label>Step: </label>
                <input
                    name="step"
                    type="number"
                    onChange={this.handleNumberRange}
                />
                <br />
                <label>Mapping Field</label>
                <input
                    name="mapping_field"
                    type="text"
                    onChange={this.handleInputChange}
                />
                <br />
                <label>Tag</label>
                <input
                    name="tag"
                    type="text"
                    onChange={this.handleInputChange}
                />
                <br />
                <input type="submit" value="Add" />
            </Form>
        );
    };

    renderDisplay = () => {
        var render = [];
        var key = 0;
        render.push(<h1 key={key++}>{this.state.display.title}</h1>);
        render.push(<h2 key={key++}>{this.state.display.desc}</h2>);
        render.push(
            <p key={key++}>
                <b>Type: </b> {this.state.display.answer_type}
            </p>
        );
        render.push(
            <p key={key++}>
                Unit: {this.state.display.question_number_range.unit}
                <br />
                Low: {this.state.display.question_number_range.low}
                <br />
                High: {this.state.display.question_number_range.high}
                <br />
                Step: {this.state.display.question_number_range.step}
            </p>
        );
        render.push(
            <p key={key++}>
                <b>Mapping field:</b> {this.state.display.mapping_field}
            </p>
        );
        render.push(
            <h5 key={key++}>
                <b>Tag:</b> {this.state.display.tag}
            </h5>
        );
        render.push(<hr key={key++} />);
        return render;
    };

    render() {
        let render = null;
        if (this.state.mode === "DISPLAY") render = this.renderDisplay();
        else if (this.state.mode === "EDIT") render = this.renderEdit();
        return (
            <Container>
                <Row>
                    <Col
                        style={{
                            backgroundColor: "#eeeeee",
                            margin: "20px",
                        }}
                    >
                        {render}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default NumberQuestion;
