import React, { Component } from "react";

import UserStats from "./UserStats";
import { Container, Col, Row } from "react-bootstrap";
import UserGrowthGraph from "./UserGrowthGraph";
import CanvasStats from "./CanvasStats";
import CanvasGrowthGraph from "./CanvasGrowthGraph";
import "../../stylesheets/sidenav.css";

class Statistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: "USERS",
        };
    }

    renderContent = () => {
        return (
            <Col>
                <Row className='justify-content-md-center'>
                    <h1>User Stats</h1>
                </Row>
                <Row className='justify-content-md-center'>
                    <UserStats
                        key={1}
                        userType={["MAKER", "DESIGNER_MAKER"]}
                        title={"Makers"}
                    ></UserStats>
                    <UserStats
                        key={2}
                        userType={["DESIGNER", "DESIGNER_MAKER"]}
                        title={"Designers"}
                    ></UserStats>
                    <UserStats
                        key={3}
                        userType={["USER"]}
                        title={"Users"}
                    ></UserStats>
                </Row>
                <Row className='justify-content-md-center'>
                    <UserGrowthGraph
                        key={1}
                        userType={["MAKER", "DESIGNER_MAKER"]}
                        title={"Makers"}
                    ></UserGrowthGraph>
                    <UserGrowthGraph
                        key={2}
                        userType={["DESIGNER", "DESIGNER_MAKER"]}
                        title={"Designers"}
                    ></UserGrowthGraph>
                    <UserGrowthGraph
                        key={3}
                        userType={["USER"]}
                        title={"Users"}
                    ></UserGrowthGraph>
                </Row>
                <Row className='justify-content-md-center'>
                    <CanvasStats key={1} title={"Canvases"}></CanvasStats>
                    <CanvasGrowthGraph
                        key={1}
                        title={"Canvas Growth"}
                    ></CanvasGrowthGraph>
                </Row>
            </Col>
        );
    };

    changeMode = (mode) => {
        console.log(mode);
        this.setState({
            mode: mode,
        });
    };

    render() {
        const content = this.renderContent();
        return <div style={{ width: "100%" }}>{content}</div>;
    }
}

export default Statistics;
