import React from 'react';
import { connect } from 'react-redux'   
import {Image, Row, Modal} from 'react-bootstrap';
import { withRouter } from 'react-router-dom'
import userIcon from '../../images/placeholder_profile_pic.png'
import expand from '../../images/expand.png'
import collapse from '../../images/collapse.png';
import {FRONTEND} from '../../secrets.js';
import {declineComment, approveComment} from '../../actions/commentsActions'

class CommentTile extends React.Component{

  state = {
    showMedia: false,
    declineWarning:false,
  }

  timeDifference(current, previous) {

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
         return Math.round(elapsed/1000) + ' seconds ago';   
    }

    else if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + ' mins ago';   
    }

    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour ) + ' h ago';   
    }

    else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' days ago';   
    }

    else if (elapsed < msPerYear) {
        return  Math.round(elapsed/msPerMonth) + ' months ago';   
    }

    else {
        return Math.round(elapsed/msPerYear ) + ' y ago';   
    }
  }

  renderUserProfile = () => {
    let date = new Date(this.props.data.updatedAt)
    let now = new Date()
    let diff = this.timeDifference(now, date)
    return(
      <Row className="user-profile">
        <Image src={this.props.data.user.profile_pic ? this.props.data.user.profile_pic : userIcon}/>
        <div className="name-time-container">
          <h6>{this.props.data.user.name}</h6>
          <p>{diff}</p>
        </div>
      </Row>
    )
  }

  decline = () => {
    let data = {
      id:this.props.data._id
    }
    this.props.declineComment(data);
    this.setState({declineWarning:false})
  }

  approve = () => {
    this.props.approveComment({
        id:this.props.data._id,
        approved:true,
        canvas_id:this.props.data.canvas_id
    })
  }

  render(){
    return(
      <div className="comment-tile">
        {this.renderUserProfile()}
        <Row style={{flexWrap:'wrap-reverse', alignItems:'center'}}>
          <div className="comment-text-container">
            <div dangerouslySetInnerHTML={{__html: this.props.data.comment_text}}></div>
          </div>
          <div className="comment-actions-container">
            <button className="accept-btn" onClick={this.approve}>Accept</button>
            <button className="decline-btn" onClick={()=>this.setState({declineWarning:true})}>Decline</button>
            <button className="view-canvas-btn" onClick={()=>{
              window.location.href=`${FRONTEND}/canvas/${this.props.data.canvas_id}`
            }}>View Canvas</button>
          </div>
        </Row>
        <div className="images-container">
          <div style={{width:'100%', display:'flex', alignItems:'center'}}>
            <img src={this.state.showMedia ? collapse : expand} onClick = {()=>{this.setState({showMedia:!this.state.showMedia})}} style={{width:'2rem', cursor:'pointer'}}/>
            <h6>{this.state.showMedia ? "hide attached media" : "show attached media"}</h6>
          </div>
          {
            this.state.showMedia && (
              <div>
                {
                  this.props.data.comment_image_uploads !== undefined && this.props.data.comment_image_uploads.length> 0 
                  && this.props.data.comment_image_uploads.map(img => <Image fluid key={img._id} src={img}/>)
                }
              </div>
            )
          }
        </div>
        {/* warning modal */}
        <Modal show={this.state.declineWarning} onHide={()=>{this.setState({declineWarning:false})}} centered>
          <Modal.Header closeButton style={{border:'none'}}></Modal.Header>
          <Modal.Body style={{ fontWeight:700, textAlign:'center'}}>
              Are you sure you want to decline this comment? 
          </Modal.Body>
          <Modal.Footer style={{border:'none'}}>
          <button style={{color:'red', backgroundColor:'#FFFFFF', fontWeight:700, border:'none', flex:1, margin:0, textTransform:'lowercase'}} onClick={this.decline}>
              Yes
          </button>
          <button style={{color:'#999999', backgroundColor:'#FFFFFF', fontWeight:700, border:'none', flex:1, margin:0, textTransform:'lowercase'}} onClick={()=>{this.setState({declineWarning:false})}}>
              No
          </button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { 
      // approval_comments:state.approval_comments
  }
}

const mapDispatchToProps = (dispatch) => {

  return { 
    declineComment: (data) => dispatch(declineComment(data)), 
    approveComment: (data) => dispatch(approveComment(data)), 
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CommentTile))