import React, { Component } from 'react'
import {Container, Row, Col, Button, Form} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import {ENDPOINT} from '../../secrets'
import axios from 'axios'
import Question from './Question'

class QuestionManager extends Component{
    constructor(props)
    {
        super(props)
        this.state = {
            questions : [],
            nCreateQuestions: 0,
            filterTag: "",
            duplicateMode: false,
            duplicateQuestion: null
        }
        this.deleted = false
    }

    deleteQuestion = (id) =>
    {
        axios.delete(ENDPOINT + "api/question?id=" + id).then(
            response => {
                // console.log(response)
                this.deleted = true
                this.forceUpdate()
            }
        )
    }

    duplicateQuestion = (data) =>
    {
        this.setState({duplicateMode: true, duplicateQuestion: data})
    }

    componentDidMount()
    {
        axios.get(ENDPOINT + "api/question", {crossDomain: true})
                .then(response => response.data).then(response => {
                    let questions = response.questions
                    this.setState({questions: questions, allQuestions: questions})
                })
    }

    renderQuestions = () =>
    {
        let QuestionsRender = []
        this.state.questions.forEach(question_data =>
        {
            QuestionsRender.push(<Question key={question_data._id} data={question_data} deleteFunction={this.deleteQuestion} duplicateFunction={this.duplicateQuestion}></Question>)
        })
        for(let i=0; i< this.state.nCreateQuestions; i++)
        {
            QuestionsRender.push(<Question key={i} mode="CREATE" deleteFunction={this.deleteQuestion}></Question>)
        }

        if(this.state.duplicateMode && this.state.duplicateQuestion)
        {
            QuestionsRender.push(<Question key={"duplicate"} mode="DUPLICATE" data={this.state.duplicateQuestion} deleteFunction={this.deleteQuestion}></Question>)
        }
        return QuestionsRender
    }

    addNewQuestionForm = () =>
    {
        let nCreateQuestions = this.state.nCreateQuestions+1
        this.setState({nCreateQuestions: nCreateQuestions})
    }

    filterQuestions = (e) =>
    {
        e.preventDefault()
        let filteredQuestions = this.state.allQuestions.filter(x => x.tag? x.tag.includes(this.state.filterTag): false)
        this.setState({questions: filteredQuestions})
    }

    componentDidUpdate()
    {
        if(this.deleted)
        {
            this.deleted = false
        axios.get(ENDPOINT + "api/question", {crossDomain: true})
                .then(response => response.data).then(response => {
                    let questions = response.questions
                    this.setState({questions: questions})
                })
        }
    }

    handleChange = (e) =>
    {
        e.preventDefault()
        this.setState({[e.target.name]: e.target.value})
    }

    render()
    {
        let QuestionsRender = this.renderQuestions()

        return (

            <Container>
                <Row style={{"position": "relative"}}>
                    <Col xs={12} lg={{span: 8, offset:2}}>
                        <h2>Questions</h2>
                        <Col xs={12}>
                            <Row>
                            <Col xs={12} lg={6}>
                                <Form.Control type="text" value={this.state.filterTag} name="filterTag" onChange={this.handleChange} onKeyUp={this.filterQuestions}></Form.Control>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Button className="col-8 offset-2" variant="success" onClick={this.addNewQuestionForm}>Create New Question</Button>
                            </Col>
                            </Row>
                        </Col>
                    </Col>
                    <Col xs={12} lg={{span: 8, offset:2}} className="vertical-center">
                        {QuestionsRender}
                    </Col>
                    
                </Row>
            </Container>
        )
    }
}

export default withRouter(QuestionManager)