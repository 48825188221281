import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import QuestionManager from "./components/questionPanel/QuestionManager";
import CanvasManager from "./components/canvasPanel/CanvasManager";
import CompetitionManager from "./components/canvasPanel/CompetitionManager";
import TransactionManager from "./components/transactionPanel/TransactionManager";
import SurveyManager from "./components/questionPanel/SurveyManager";
import UserManager from "./components/userPanel/UserManager";
import CommentsManager from "./components/commentsPanel/Comments";

import Statistics from "./components/statisticsPanel/Statistics";

import { StripeProvider } from "react-stripe-elements";
import { getAllCanvases } from "./actions/canvasActions";
import "./App.css";

class App extends Component {
    componentDidMount() {
        this.props.getAllCanvases();
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route path='/transaction-manager'>
                        <StripeProvider apiKey='pk_test_b6X4exUNxQ6pIt4hg9eGkv7p00Ef7uWBhi'>
                            <TransactionManager></TransactionManager>
                        </StripeProvider>
                    </Route>

                    <Route path='/canvas-manager'>
                        <CanvasManager></CanvasManager>
                    </Route>

                    <Route path='/comments-manager'>
                        <CommentsManager></CommentsManager>
                    </Route>

                    <Route path='/competition-manager'>
                        <CompetitionManager></CompetitionManager>
                    </Route>

                    <Route path='/questions'>
                        <QuestionManager></QuestionManager>
                    </Route>

                    <Route path='/survey-manager'>
                        <SurveyManager></SurveyManager>
                    </Route>

                    <Route path='/user-manager'>
                        <UserManager></UserManager>
                    </Route>

                    <Route path='/statistics'>
                        <Statistics></Statistics>
                    </Route>

                    <Route path='/'>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <Link to="/transaction-manager">Transaction Manager</Link>
                            <Link to="/canvas-manager">Canvas Manager</Link>
                            <Link to="/competition-manager">Competition Manager</Link>
                            <Link to="/Questions">Questions</Link>
                            <Link to="/survey-manager">Survey Manager</Link>
                            <Link to="/user-manager">User Manager</Link>
                            <Link to="/statistics">Statistics</Link>
                        </div>
                    </Route>
                </Switch>
            </Router>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllCanvases: () => dispatch(getAllCanvases()),
        // getCanvas: (id) => dispatch(getCanvas(id)),
        // updateCanvas: (data) => dispatch(updateCanvas(data))
    };
};

export default connect(null, mapDispatchToProps)(App);
