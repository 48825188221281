import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";

/**
 * TextQuestion component used to create and display TEXT questions
 */
class TagsQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                title: "",
                desc: "",
                answer_type: "TAGS",
                mapping_field: "",
                tag: "",
            },
            display: this.props.display,
            mode: this.props.mode,
        };
    }

    handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            form: {
                ...this.state.form,
                [name]: value,
            },
        });
    };

    handleTagInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (value.indexOf(",") >= 0) {
            var tags = this.state.form[name];
            if (!tags) {
                tags = [];
            }
            var newtags = value.split(",");
            newtags = newtags.slice(0, newtags.length - 1);
            tags = tags.concat(newtags);
            e.target.value = "";
            this.setState({
                form: {
                    ...this.state.form,
                    [name]: tags,
                },
            });
        }
    };

    listTags = (name) => {
        const tags = this.state.form[name];
        if (!tags) return null;
        var render = [];
        tags.forEach((tag, index) => render.push(<li key={index}>{tag}</li>));
        return render;
    };

    listDisplayTags = () => {
        const tags = this.state.display.sample_tags;
        if (!tags) return null;
        var render = [];
        tags.forEach((tag, index) => render.push(<li key={index}>{tag}</li>));
        return render;
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.handleSubmit(this.state.form);
    };

    renderEdit = () => {
        const tagList = this.listTags("sample_tags");
        return (
            <Form onSubmit={this.handleSubmit}>
                <label>Title</label>
                <input
                    name="title"
                    type="text"
                    onChange={this.handleInputChange}
                />
                <br />
                <label>Description</label>
                <input
                    name="desc"
                    type="text"
                    onChange={this.handleInputChange}
                />
                <br />
                <label>Sample Tags</label>
                <br />
                {tagList}
                <input
                    name="sample_tags"
                    type="text"
                    onChange={this.handleTagInputChange}
                />
                <br />
                <label>Mapping Field</label>
                <input
                    name="mapping_field"
                    type="text"
                    onChange={this.handleInputChange}
                />
                <br />
                <label>Tag</label>
                <input
                    name="tag"
                    type="text"
                    onChange={this.handleInputChange}
                />
                <br />
                <input type="submit" value="Add" />
            </Form>
        );
    };

    renderDisplay = () => {
        var render = [];
        var key = 0;
        const tagList = this.listDisplayTags();
        render.push(<h1 key={key++}>{this.state.display.title}</h1>);
        render.push(<h2 key={key++}>{this.state.display.desc}</h2>);
        render.push(
            <p key={key++}>
                <b>Type: </b> {this.state.display.answer_type}
            </p>
        );
        render.push(tagList);
        render.push(
            <p key={key++}>
                <b>Mapping field:</b> {this.state.display.mapping_field}
            </p>
        );
        render.push(
            <h5 key={key++}>
                <b>Tag:</b> {this.state.display.tag}
            </h5>
        );
        render.push(<hr key={key++} />);
        return render;
    };

    render() {
        let render = null;
        if (this.state.mode === "DISPLAY") render = this.renderDisplay();
        else if (this.state.mode === "EDIT") render = this.renderEdit();
        return (
            <Container>
                <Row>
                    <Col
                        style={{
                            backgroundColor: "#eeeeee",
                            margin: "20px",
                        }}
                    >
                        {render}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default TagsQuestion;
