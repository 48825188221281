import React, { Component } from "react";
import { Container, Row, Col, Image, Form } from "react-bootstrap";
import axios from "axios";
import { ENDPOINT } from "../../secrets";

/**
 * OptionsQuestion component used to create and display OPTION questions
 */
class OptionsQuestion extends Component {
    constructor(props) {
        super(props);
        this.index = 0;
        this.state = {
            form: {
                title: "",
                desc: "",
                answer_type: "OPTIONS",
                mapping_field: "",
                question_options: [],
                tag: "",
            },
            display: this.props.display,
            mode: this.props.mode,
            imageFile: null,
        };
        this.fileInput = React.createRef();
    }

    handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            form: {
                ...this.state.form,
                [name]: value,
            },
        });
    };

    handleFileSelect = (e) => {
        e.preventDefault();
        if (e.target.files.length > 0 && e.target.files[0]) {
            this.setState({ imageFile: e.target.files[0] });
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.handleSubmit(this.state.form);
    };

    handleAddOptionChange = (e) => {
        if (e.target.name === "optionTitle")
            this.setState({
                newOption: {
                    ...this.state.newOption,
                    title: e.target.value,
                },
            });
        else if (e.target.name === "optionValue")
            this.setState({
                newOption: {
                    ...this.state.newOption,
                    value: e.target.value,
                },
            });
        else if (e.target.name === "optionAlt")
            this.setState({
                newOption: {
                    ...this.state.newOption,
                    alt: e.target.value,
                },
            });
    };

    addNewOption = (e) => {
        e.preventDefault();

        const image = this.state.imageFile;
        if (!image) {
            this.setState({
                newOption: {
                    ...this.state.newOption,
                    image: null,
                },
            });
            var options = this.state.form.question_options;
            options.push(this.state.newOption);
            this.setState({
                form: {
                    ...this.state.form,
                    question_options: options,
                },
            });
            return;
        }

        const formData = new FormData();
        formData.append("file", image, image.name);

        axios
            .post(`${ENDPOINT}api/upload`, formData, { crossDomain: true })
            .then((response) => {
                this.setState({
                    newOption: {
                        ...this.state.newOption,
                        image: response.data.link,
                    },
                });
                var options = this.state.form.question_options;
                options.push(this.state.newOption);
                this.setState({
                    form: {
                        ...this.state.form,
                        question_options: options,
                    },
                });
            })
            .catch((err) => {
                alert("Error uploading image!");
                console.log(err);
            });
    };

    renderFormOptions = () => {
        var options = this.state.form.question_options;
        let render = [];
        if (options.length <= 0) return <p>---Nothing Added Yet---</p>;
        options.forEach((option) => {
            render.push(
                <li key={this.index++}>
                    {option.title}
                    <Image
                        width="100px"
                        src={option.image}
                        alt={option.alt}
                    ></Image>
                </li>
            );
        });
        return render;
    };

    renderEdit = () => {
        const renderFormOptions = this.renderFormOptions();
        return (
            <Form onSubmit={this.handleSubmit}>
                <label>Title</label>
                <input
                    name="title"
                    type="text"
                    onChange={this.handleInputChange}
                />
                <br />
                <label>Description</label>
                <input
                    name="desc"
                    type="text"
                    onChange={this.handleInputChange}
                />
                <br />
                <label>Options</label>
                <br />
                {renderFormOptions}
                <br />
                <input
                    onChange={this.handleAddOptionChange}
                    name="optionTitle"
                    placeholder="Title"
                    type="text"
                ></input>
                <br />
                <input
                    type="file"
                    onChange={this.handleFileSelect}
                    accept="image/*"
                    name="optionImage"
                    placeholder="Visible"
                    ref={this.fileInput}
                ></input>
                <input
                    type="text"
                    onChange={this.handleAddOptionChange}
                    name="optionAlt"
                    placeholder="Alt text"
                ></input>
                <input
                    type="text"
                    onChange={this.handleAddOptionChange}
                    name="optionValue"
                    placeholder="value"
                ></input>
                <button onClick={this.addNewOption}>Add</button>
                <br />
                <label>Mapping Field</label>
                <input
                    name="mapping_field"
                    type="text"
                    onChange={this.handleInputChange}
                />
                <br />
                <label>Tag</label>
                <input
                    name="tag"
                    type="text"
                    onChange={this.handleInputChange}
                />
                <br />
                <input type="submit" value="Add" />
            </Form>
        );
    };

    renderDisplayOptions = () => {
        var options = [];
        options = this.state.display.question_options;
        let render = [];
        options.forEach((option) => {
            render.push(
                <li key={this.index++}>
                    Title: {option.title}, Value: {option.value}
                    <br />
                    <Image
                        width="100px"
                        src={option.image}
                        alt={option.alt}
                    ></Image>
                </li>
            );
        });
        return render;
    };

    renderDisplay = () => {
        const renderDisplayOptions = this.renderDisplayOptions();
        return (
            <div>
                <h1>{this.state.display.title}</h1>
                <h2>{this.state.display.desc}</h2>
                <p>
                    <b>Type: </b> {this.state.display.answer_type}
                </p>
                {renderDisplayOptions}
                <p>
                    <b>Mapping field:</b> {this.state.display.mapping_field}
                </p>
                <h5>
                    <b>Tag:</b> {this.state.display.tag}
                </h5>
                <hr />
            </div>
        );
    };

    render() {
        let render = null;
        if (this.state.mode === "DISPLAY") render = this.renderDisplay();
        else if (this.state.mode === "EDIT") render = this.renderEdit();
        return (
            <Container>
                <Row>
                    <Col
                        style={{
                            backgroundColor: "#eeeeee",
                            margin: "20px",
                        }}
                    >
                        {render}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default OptionsQuestion;
