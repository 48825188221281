import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";

/**
 * SelectQuestion component used to create and display SELECT questions
 */
class SelectQuestion extends Component {
    constructor(props) {
        super(props);
        this.index = 0;
        this.state = {
            form: {
                title: "",
                desc: "",
                answer_type: "SELECT",
                mapping_field: "",
                question_select: [],
                tag: "",
            },
            display: this.props.display,
            mode: this.props.mode,
        };
    }

    handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            form: {
                ...this.state.form,
                [name]: value,
            },
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.handleSubmit(this.state.form);
    };

    renderFormOptions = () => {
        var options = this.state.form.question_select;
        let render = [];
        if (options.length <= 0) return <p>---Nothing Added Yet---</p>;
        options.forEach((option) => {
            render.push(<li key={this.index++}>{option.title}</li>);
        });
        return render;
    };

    handleAddOptionChange = (e) => {
        if (e.target.name === "optionTitle")
            this.setState({
                newOption: {
                    ...this.state.newOption,
                    title: e.target.value,
                },
            });
        else if (e.target.name === "optionValue")
            this.setState({
                newOption: {
                    ...this.state.newOption,
                    value: e.target.value,
                },
            });
    };

    addSelectOption = (e) => {
        e.preventDefault();
        var options = this.state.form.question_select;
        options.push(this.state.newOption);
        this.setState({
            form: {
                ...this.state.form,
                question_select: options,
            },
        });
    };

    renderEdit = () => {
        const renderFormOptions = this.renderFormOptions();
        return (
            <Form onSubmit={this.handleSubmit}>
                <label>Title</label>
                <input
                    name="title"
                    type="text"
                    onChange={this.handleInputChange}
                />
                <br />
                <label>Description</label>
                <input
                    name="desc"
                    type="text"
                    onChange={this.handleInputChange}
                />
                <br />
                <label>Options</label>
                <br />
                {renderFormOptions}
                <br />
                <input
                    onChange={this.handleAddOptionChange}
                    name="optionTitle"
                    placeholder="Visible"
                ></input>
                <input
                    onChange={this.handleAddOptionChange}
                    name="optionValue"
                    placeholder="Value"
                ></input>
                <button onClick={this.addSelectOption}>Add</button>
                <br />
                <label>Mapping Field</label>
                <input
                    name="mapping_field"
                    type="text"
                    onChange={this.handleInputChange}
                />
                <br />
                <label>Tag</label>
                <input
                    name="tag"
                    type="text"
                    onChange={this.handleInputChange}
                />
                <br />
                <input type="submit" value="Add" />
            </Form>
        );
    };

    renderSelectOptions = () => {
        var options = [];
        options = this.state.display.question_select;
        let render = [];
        options.forEach((option) => {
            render.push(
                <li key={this.index++}>
                    Title: {option.title}, Value: {option.value}
                </li>
            );
        });
        return render;
    };

    renderDisplay = () => {
        const renderSelectOptions = this.renderSelectOptions();
        return (
            <div>
                <h1>{this.state.display.title}</h1>
                <h2>{this.state.display.desc}</h2>
                <p>
                    <b>Type: </b> {this.state.display.answer_type}
                </p>
                {renderSelectOptions}
                <p>
                    <b>Mapping field:</b> {this.state.display.mapping_field}
                </p>
                <h5>
                    <b>Tag:</b> {this.state.display.tag}
                </h5>
                <hr />
            </div>
        );
    };

    render() {
        let render = null;
        if (this.state.mode === "DISPLAY") render = this.renderDisplay();
        else if (this.state.mode === "EDIT") render = this.renderEdit();
        return (
            <Container>
                <Row>
                    <Col
                        style={{
                            backgroundColor: "#eeeeee",
                            margin: "20px",
                        }}
                    >
                        {render}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default SelectQuestion;
