import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { ENDPOINT } from "../../secrets";
import axios from "axios";

import TextQuestion from "./TextQuestion";
import SelectQuestion from "./SelectQuestion";
import OptionsQuestion from "./OptionsQuestion";
import NumberQuestion from "./NumberQuestion";
import RangeQuestion from "./RangeQuestion";
import LocationQuestion from "./LocationQuestion";
import UploadQuestion from "./UploadQuestion";
import DimensionQuestion from "./DimensionQuestion";
import TagsQuestion from "./TagsQuestion";

import "../../stylesheets/surveymanager.css";

class SurveyManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questions: [],
            form: {
                title: "Sample",
                desc: "Sample desc",
                answer_type: "TEXT",
                mapping_field: "name",
                tag: "NA",
            },
            addmode: "",
            tagfilter: null,
        };
    }
    componentDidMount() {
        axios
            .get(ENDPOINT + "api/survey", { crossDomain: true })
            .then((response) => {
                const questions = response.data.question;
                this.setState({ questions: questions });
            });
    }

    getQuestions(tag) {
        const url = `api/survey` + (tag ? `?tag=${tag}` : ``);
        axios.get(ENDPOINT + url, { crossDomain: true }).then((response) => {
            const questions = response.data.question;
            this.setState({ questions: questions });
        });
    }

    deleteQuestion(id) {
        console.log(id);
        const url = `api/survey`;
        axios
            .delete(ENDPOINT + url, { crossDomain: true, data: { id: id } })
            .then((response) => {
                console.log("Deleted question");
                this.getQuestions();
            })
            .catch((err) => {
                console.log("Error deleting question");
                console.log(err);
                alert("Unable to delete the question!");
            });
    }

    tagFilter = (e) => {
        var tag = e.target.value;
        if (tag === "") tag = null;
        this.setState({
            tagfilter: tag,
        });
    };

    handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value,
        });
    };

    handleSubmit = (data) => {
        console.log(data);
        axios
            .post(ENDPOINT + "api/survey", data)
            .then((response) => {
                if (!response.data) console.log("No received!");
                if (response.data.status) {
                    console.log("Post successful!");
                    this.getQuestions();
                } else console.log("Request Failed!");
            })
            .catch((error) => {
                if (error.response) {
                    // Request made and server responded
                    console.log("Request complete error");
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log("No response error");
                    console.log(error.request);
                } else {
                    console.log("Unable to send error");
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                }
            });
    };

    renderQuestionDisplay = (questionData, index) => {
        let render;
        switch (questionData.answer_type) {
            case "TEXT":
                render = (
                    <TextQuestion
                        key={index}
                        mode="DISPLAY"
                        display={questionData}
                    ></TextQuestion>
                );
                break;
            case "SELECT":
                render = (
                    <SelectQuestion
                        key={index}
                        mode="DISPLAY"
                        display={questionData}
                    ></SelectQuestion>
                );
                break;
            case "OPTIONS":
                render = (
                    <OptionsQuestion
                        key={index}
                        mode="DISPLAY"
                        display={questionData}
                    ></OptionsQuestion>
                );
                break;
            case "NUMBER":
                render = (
                    <NumberQuestion
                        key={index}
                        mode="DISPLAY"
                        display={questionData}
                    ></NumberQuestion>
                );
                break;
            case "RANGE":
                render = (
                    <RangeQuestion
                        key={index}
                        mode="DISPLAY"
                        display={questionData}
                    ></RangeQuestion>
                );
                break;
            case "LOCATION":
                render = (
                    <LocationQuestion
                        key={index}
                        mode="DISPLAY"
                        display={questionData}
                    ></LocationQuestion>
                );
                break;
            case "UPLOAD":
                render = (
                    <UploadQuestion
                        key={index}
                        mode="DISPLAY"
                        display={questionData}
                    ></UploadQuestion>
                );
                break;
            case "DIMENSIONS":
                render = (
                    <DimensionQuestion
                        key={index}
                        mode="DISPLAY"
                        display={questionData}
                    ></DimensionQuestion>
                );
                break;
            case "TAGS":
                render = (
                    <TagsQuestion
                        key={index}
                        mode="DISPLAY"
                        display={questionData}
                    ></TagsQuestion>
                );
                break;
            default:
                return null;
        }
        return (
            <Container>
                {render}
                <button onClick={() => this.deleteQuestion(questionData._id)}>
                    Delete
                </button>
                <hr />
            </Container>
        );
    };

    renderQuestions = () => {
        let renderResult = [];
        this.state.questions.forEach((questionsData, index) => {
            renderResult.push(this.renderQuestionDisplay(questionsData, index));
        });
        return renderResult;
    };

    addQuestion = (e) => {
        const type = e.target.name;
        this.setState({
            addmode: type,
        });
    };

    renderAddQuestion = () => {
        let renderAddQuestion = null;
        switch (this.state.addmode) {
            case "TEXT":
                renderAddQuestion = (
                    <TextQuestion
                        mode="EDIT"
                        handleSubmit={this.handleSubmit}
                    ></TextQuestion>
                );
                break;
            case "SELECT":
                renderAddQuestion = (
                    <SelectQuestion
                        mode="EDIT"
                        handleSubmit={this.handleSubmit}
                    ></SelectQuestion>
                );
                break;
            case "OPTIONS":
                renderAddQuestion = (
                    <OptionsQuestion
                        mode="EDIT"
                        handleSubmit={this.handleSubmit}
                    ></OptionsQuestion>
                );
                break;
            case "NUMBER":
                renderAddQuestion = (
                    <NumberQuestion
                        mode="EDIT"
                        handleSubmit={this.handleSubmit}
                    ></NumberQuestion>
                );
                break;
            case "RANGE":
                renderAddQuestion = (
                    <RangeQuestion
                        mode="EDIT"
                        handleSubmit={this.handleSubmit}
                    ></RangeQuestion>
                );
                break;
            case "LOCATION":
                renderAddQuestion = (
                    <LocationQuestion
                        mode="EDIT"
                        handleSubmit={this.handleSubmit}
                    ></LocationQuestion>
                );
                break;
            case "UPLOAD":
                renderAddQuestion = (
                    <UploadQuestion
                        mode="EDIT"
                        handleSubmit={this.handleSubmit}
                    ></UploadQuestion>
                );
                break;
            case "DIMENSIONS":
                renderAddQuestion = (
                    <DimensionQuestion
                        mode="EDIT"
                        handleSubmit={this.handleSubmit}
                    ></DimensionQuestion>
                );
                break;
            case "TAGS":
                renderAddQuestion = (
                    <TagsQuestion
                        mode="EDIT"
                        handleSubmit={this.handleSubmit}
                    ></TagsQuestion>
                );
                break;
            default:
                renderAddQuestion = null;
        }
        return renderAddQuestion;
    };

    render() {
        const renderQuestions = this.renderQuestions();
        const renderAddQuestion = this.renderAddQuestion();
        return (
            <Container>
                <h1>Survey Questions</h1>
                <label>TAG </label>
                <input type="text" onChange={this.tagFilter}></input>
                <button
                    onClick={() => {
                        this.getQuestions(this.state.tagfilter);
                    }}
                >
                    Filter
                </button>
                <hr style={{ border: "2px solid grey" }} />
                <Row>
                    <Col>{renderQuestions}</Col>
                </Row>
                <h1>Add new Questions</h1>
                <hr style={{ border: "3px solid black" }} />
                {renderAddQuestion}
                <button name="TEXT" onClick={this.addQuestion}>
                    Add Text Question
                </button>
                <button name="SELECT" onClick={this.addQuestion}>
                    Add Select Question
                </button>
                <button name="OPTIONS" onClick={this.addQuestion}>
                    Add Options Question
                </button>
                <button name="NUMBER" onClick={this.addQuestion}>
                    Add Number Question
                </button>
                <button name="RANGE" onClick={this.addQuestion}>
                    Add Range Question
                </button>
                <button name="LOCATION" onClick={this.addQuestion}>
                    Add Location Question
                </button>
                <button name="UPLOAD" onClick={this.addQuestion}>
                    Add Upload Question
                </button>
                <button name="DIMENSIONS" onClick={this.addQuestion}>
                    Add Dimensions Question
                </button>
                <button name="TAGS" onClick={this.addQuestion}>
                    Add Tags Question
                </button>
            </Container>
        );
    }
}

export default withRouter(SurveyManager);
