import React, { Component } from "react";
import {
    LineSeries,
    XYPlot,
    VerticalGridLines,
    HorizontalGridLines,
    XAxis,
    YAxis,
} from "react-vis";
import { ENDPOINT } from "../../secrets";
import axios from "axios";
import { Container, Row } from "react-bootstrap";
import moment from "moment";

class UserGrowthGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            userType: this.props.userType,
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        const url = `${ENDPOINT}api/statistics/users`;
        const query = {
            user_type: { $in: this.state.userType },
        };
        axios
            .post(url, query)
            .then((response) => {
                const users = response.data.users;
                this.setState({
                    users: users,
                    loading: false,
                });
                console.log(users.length);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    tickFormatter = (value, index, scale, tickTotal) => {
        const time = moment(value);
        return time.format("DD MMM YYYY");
    };

    renderGraph = () => {
        var data = [];
        var count = 0;
        var prev = 0;
        this.state.users.forEach((user) => {
            count++;
            const time = new Date(user.createdAt).getTime();
            if (time > prev) {
                prev = time;
            } else {
                console.log("Decrease");
            }
            data.push({ x: time, y: count });
        });
        var data_rev = data.slice();
        data_rev = data_rev.reverse();
        data = [...data, ...data_rev];

        const lineStyle = {
            stroke: "#9af",
            line: {
                strokeWidth: "2px",
            },
        };
        const gridStyle = {
            stroke: "#cdf",
            strokeDasharray: "5,5",
        };
        return (
            <XYPlot height={300} width={1000}>
                <VerticalGridLines style={gridStyle} />
                <HorizontalGridLines style={gridStyle} />
                <YAxis style={lineStyle} title={"Number"} />
                <XAxis
                    tickTotal={10}
                    style={lineStyle}
                    tickFormat={this.tickFormatter}
                    title={"Time"}
                />
                <LineSeries data={data} color='#535b3e' strokeWidth={5} />
            </XYPlot>
        );
    };

    render() {
        return (
            <Container
                style={{
                    borderBottom: "1px solid #999",
                    margin: "20px 0",
                    padding: "20px 20px",
                }}
            >
                <Row>
                    <h3>{this.props.title}</h3>
                </Row>
                <Row>{this.renderGraph()}</Row>
            </Container>
        );
    }
}

export default UserGrowthGraph;
